$colors: ( 
  default: #999999,
  primary: #1D9AD6,
  primary-dark: #0C85A1,
  secondary: #33B6D4,
  warning: #F7941E,
  warning-dark: #E78005,
  success: #2ECC71,
  success-dark: #11AF54,
  danger: #e74c3c,
  danger-dark: #C92A1A,
  light: #888888,
  dark: #444444,
  tertiary: #9b59b6,
  gray: #f5f5f5,
  yellow: #f1c40f,
  turquoise: #1abc9c,
  blue: #3498db,
  border-gray: #eee,
  lighter-gray: #fdfdfd,
  white: #fff,
  topship: #e67e22,
  black: #000,
  neutral: #34495e
);
$borders: (
  default: #E5E5E5,
  dark: #C5C5C5,
  light: #F5F5F5
);
$backgrounds: (
  default: #F5F5F5,
  dark: #F0F0F0,
  light: #F5F5F5
);
$fonts: (
  small: 0.7rem,
  big: 1.2rem,
  bigger: 1.4rem,
  large: 1.8rem,
  larger: 2rem
);
$status: (
  default: 'default',
  new: 'default',
  created: 'default',
  unknown: 'default',
  processing: 'topship',
  picking: 'topship',
  holding: 'topship',
  delivering: 'topship',
  returning: 'topship',
  undeliverable: 'danger',
  cancelled: 'danger',
  error: 'danger',
  fail: 'danger',
  delivered: 'success',
  success: 'success',
  received: 'tertiary',
  returned: 'tertiary',
  ignore: 'neutral',
  Z: 'default',
  N: 'danger',
  S: 'topship',
  NS: 'tertiary',
  P: 'success'
);
$order-source: (
  default: 'default',
  ts_app: 'topship',
  etop_pos: 'secondary',
  etop_pxs: 'yellow',
  etop_cmx: 'turquoise',
  haranvan: 'tertiary',
  api: 'tertiary',
  self: 'blue',
  import: 'blue'
);
$ticket-status: (
  Open: 'primary',
  Closed: 'secondary'
);
$sidebar-width: 45px;
$sidebar-width-mobile: 45px;
$sidebar-width-desktop: 260px;
:root {
    @each $name, $color in $colors {
        // --#{$name}-color: #{$color};
        --#{"" + $name}-color: #{$color};
        --#{"" + $name}-color-rgb: #{'' + red($color) + ',' + green($color) + ',' + blue($color) + ''};
    }

    @each $name, $color in $borders {
        --#{"" + $name}-border-color: #{$color};
    }

    @each $name, $color in $backgrounds {
        --#{"" + $name}-bg-color: #{$color};
    }

    --sidebar-background-color: white;
    --sidebar-width: #{$sidebar-width};
    --sidebar-width-mobile: #{$sidebar-width-mobile};
    --sidebar-width-desktop: #{$sidebar-width-desktop};
    --sidebar-menu-item-height: 45px;
    --sidebar-menu-icon-height: 33px;
    --header-background-color: white;
    --header-height: 5.5rem;
    --font-size: 13px;
}