@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap&subset=vietnamese");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800&display=swap&subset=vietnamese");
:root {
  --default-color: #999999;
  --default-color-rgb: 153,153,153;
  --primary-color: #1D9AD6;
  --primary-color-rgb: 29,154,214;
  --primary-dark-color: #0C85A1;
  --primary-dark-color-rgb: 12,133,161;
  --secondary-color: #33B6D4;
  --secondary-color-rgb: 51,182,212;
  --warning-color: #F7941E;
  --warning-color-rgb: 247,148,30;
  --warning-dark-color: #E78005;
  --warning-dark-color-rgb: 231,128,5;
  --success-color: #2ECC71;
  --success-color-rgb: 46,204,113;
  --success-dark-color: #11AF54;
  --success-dark-color-rgb: 17,175,84;
  --danger-color: #e74c3c;
  --danger-color-rgb: 231,76,60;
  --danger-dark-color: #C92A1A;
  --danger-dark-color-rgb: 201,42,26;
  --light-color: #888888;
  --light-color-rgb: 136,136,136;
  --dark-color: #444444;
  --dark-color-rgb: 68,68,68;
  --tertiary-color: #9b59b6;
  --tertiary-color-rgb: 155,89,182;
  --gray-color: #f5f5f5;
  --gray-color-rgb: 245,245,245;
  --yellow-color: #f1c40f;
  --yellow-color-rgb: 241,196,15;
  --turquoise-color: #1abc9c;
  --turquoise-color-rgb: 26,188,156;
  --blue-color: #3498db;
  --blue-color-rgb: 52,152,219;
  --border-gray-color: #eee;
  --border-gray-color-rgb: 238,238,238;
  --lighter-gray-color: #fdfdfd;
  --lighter-gray-color-rgb: 253,253,253;
  --white-color: #fff;
  --white-color-rgb: 255,255,255;
  --topship-color: #e67e22;
  --topship-color-rgb: 230,126,34;
  --black-color: #000;
  --black-color-rgb: 0,0,0;
  --neutral-color: #34495e;
  --neutral-color-rgb: 52,73,94;
  --default-border-color: #E5E5E5;
  --dark-border-color: #C5C5C5;
  --light-border-color: #F5F5F5;
  --default-bg-color: #F5F5F5;
  --dark-bg-color: #F0F0F0;
  --light-bg-color: #F5F5F5;
  --sidebar-background-color: white;
  --sidebar-width: 45px;
  --sidebar-width-mobile: 45px;
  --sidebar-width-desktop: 260px;
  --sidebar-menu-item-height: 45px;
  --sidebar-menu-icon-height: 33px;
  --header-background-color: white;
  --header-height: 5.5rem;
  --font-size: 13px;
}

* {
  font-size: var(--font-size);
  font-family: "Roboto", sans-serif;
}

::placeholder {
  font-size: var(--font-size);
}

@media screen and (max-width: 1300px) {
  * {
    font-size: 12px;
  }

  ::placeholder {
    font-size: 12px;
  }
}
@media screen and (max-width: 1200px) {
  * {
    font-size: 11px;
  }

  ::placeholder {
    font-size: 11px;
  }
}
@media screen and (max-width: 1024px) {
  * {
    font-size: 10px;
  }

  ::placeholder {
    font-size: 10px;
  }
}
.layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  animation: pulse 2s;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #444444;
  width: calc(100% - var(--sidebar-width));
}

@media only screen and (min-width: 768px) {
  .show-768 {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .hide-768 {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  #toast-container {
    padding: 0 12px;
    width: 100%;
    position: fixed !important;
    top: 12px !important;
    bottom: unset !important;
    left: 0px !important;
  }

  #toast-container > .toast {
    max-width: 100%;
  }

  .etelecom-ticket .table-header {
    display: none;
  }
}
.sidebar {
  position: relative;
  background: #fff;
  z-index: 1001;
  height: 100%;
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.05);
  width: var(--sidebar-width);
}
.sidebar:hover .slider-content {
  left: var(--sidebar-width);
}

.header {
  position: relative;
  height: var(--header-height);
}

.content {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.content:has(etop-table-new) {
  padding: 1.5rem 1.5rem 0;
}

.unchangeable {
  cursor: not-allowed;
}
.unchangeable * {
  background: #ddd;
  word-break: keep-all;
  overflow-x: auto;
}

.unclickable {
  cursor: not-allowed !important;
  color: #888 !important;
  opacity: 0.8;
}

.code {
  background: #f7f7f7;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;
  font-weight: 700;
}

.badge {
  border-radius: 5px;
  background: #f8f8f8;
  border: 1px solid #f0f0f0;
}

.flex-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.page-container .total {
  border-radius: 12px;
  background: transparent !important;
}

.page-content {
  flex: 1;
  position: relative;
  padding-top: 1rem;
  overflow: hidden;
}
.page-wrapper {
  display: flex;
  position: relative;
  height: 100%;
}

.content-box td {
  padding: 6px 15px;
  border-top: none;
}

.content-box {
  background: white;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.body-transaction-detail {
  padding: 15px 15px 0;
}

.body-transaction-detail .container {
  max-width: 100% !important;
}

.alert-warning {
  height: 100%;
}

.h100 {
  height: 100%;
}

.bmt-0 {
  width: 30%;
}

.card-body {
  padding: 0;
}

.card-header {
  padding: 0;
  border: none;
}

.card-header-item {
  padding: 10px;
}
.card-header-item .header-title {
  font-size: 11px;
}

.slider-detail-info-title {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 0.9rem;
  margin-bottom: 1.25rem;
}

.slider-detail-input-item {
  margin-bottom: 0.5rem;
}

.slider-detail-section {
  padding: 1.25rem;
  border-bottom: 1px solid var(--border-gray-color);
}
.slider-detail-section:last-child {
  border-bottom: none;
}

.slider-detail-section-table {
  border-top: 1rem solid rgba(0, 0, 0, 0.04);
}
.slider-detail-section-table thead {
  background: rgba(0, 0, 0, 0.01);
}
.slider-detail-section-table th {
  color: var(--default-color);
  font-weight: 400;
  padding: 7px 15px;
  border-bottom: 1px solid var(--border-gray-color);
  border-top: 1px solid var(--border-gray-color);
}
.slider-detail-section-table td {
  padding: 10px 15px;
  font-weight: 700;
}
.slider-detail-section-table tr {
  border-bottom: 1px solid var(--border-gray-color);
}

.slider-loading, .slider-empty {
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.slider-empty {
  border-bottom: 1px solid var(--border-gray-color);
}

.slider-loading .fa {
  font-size: 1.75rem;
}

.slider-detail-full-img {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-detail-full-img img {
  object-fit: cover;
  object-position: center;
  width: 70%;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs li a {
  padding: 1rem 1.5rem;
  display: inline-block;
  text-decoration: none;
  width: 100%;
  background: #f3f3f3;
  border-bottom: 2px solid transparent;
  min-height: 48px;
}
.nav-tabs li a.active {
  background: #fff;
  border-bottom: 2px solid var(--warning-color);
}
.nav-tabs li a:hover {
  color: var(--warning-color);
}

.fa,
.fas {
  color: #6e6e6e;
}

.dropdown-item:active {
  color: initial;
}

.dropdown-item:focus {
  background-color: initial;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.font-weight-bolder {
  font-weight: 500 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.no-permission {
  cursor: not-allowed;
}
.no-permission * {
  pointer-events: none;
}

.bigger-font {
  font-size: 1.5rem !important;
}

.big-font {
  font-size: 1.3rem !important;
}

.upper-medium-font {
  font-size: 1.1rem !important;
}

.lower-medium-font {
  font-size: 0.9rem !important;
}

.small-font {
  font-size: 0.8rem !important;
}

.smaller-font {
  font-size: 0.6rem !important;
}

@page {
  size: auto;
}
@media screen {
  .print-me,
.print-me * {
    display: none !important;
  }
}
@media print {
  .no-print,
.no-print * {
    display: none !important;
  }

  .content {
    overflow: visible !important;
  }

  body, .layout {
    min-width: unset !important;
  }

  .print-page {
    page-break-after: always;
  }

  .print-me,
.print-me * {
    visibility: visible;
  }
}
.trading-box {
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.mat-input {
  border: 1px solid #dee2e6 !important;
  border-radius: 5px;
  padding: 0.75rem;
}

.textlable {
  position: absolute;
  top: -0.8rem;
  left: 0.6rem;
  background: white;
  font-size: 9px;
  padding: 0 4px;
  opacity: 0.8;
}

.aff-box {
  background-color: #fafbfb;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: rgba(90, 114, 123, 0.11) 0px 7px 30px 0px;
  border: none;
}

.not-allowed {
  cursor: not-allowed;
}

.cdk-overlay-container {
  z-index: 2000 !important;
}

.read-only {
  cursor: not-allowed;
  display: inline-flex;
  align-items: center;
  padding: 0 0.4rem;
}

.external-link-icon {
  font-size: 0.8rem;
  position: relative;
  top: -1px;
  left: 3px;
}

.wd-100 {
  min-width: 100px !important;
  max-width: 100px !important;
}

.wd-120 {
  min-width: 120px !important;
  max-width: 120px !important;
}

.wd-130 {
  min-width: 130px !important;
  max-width: 130px !important;
}

.wd-150 {
  min-width: 150px !important;
  max-width: 150px !important;
}

.md-drppicker {
  margin-top: 10px !important;
}
.md-drppicker .btn {
  background-color: var(--primary-color) !important;
  line-height: 1px !important;
  height: 30px !important;
  padding: 0 20px !important;
}
.md-drppicker .ranges button.active {
  background-color: var(--primary-color) !important;
}
.md-drppicker td.active, .md-drppicker td.active:hover {
  background-color: var(--primary-color) !important;
  border-color: transparent;
  color: #fff;
}

.position-right .md-drppicker {
  left: auto !important;
  right: 0 !important;
}

.grecaptcha-badge {
  display: none !important;
}

.show-table-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 1rem;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #eee;
  cursor: pointer;
}
.show-table-toggle .fa {
  font-size: 1.5rem;
}

.carrier-info {
  padding-left: 0.7rem;
  flex: 1;
}
.carrier-info .shipping-code {
  font-weight: 700;
  word-break: break-all;
}
.carrier-info .carrier-name {
  font-size: 0.7rem;
}
.carrier-info .carrier-name * {
  font-size: 0.7rem;
}

.carrier-logo {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 100%;
  object-fit: contain;
  object-position: center;
}

.carrier-image {
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  border: 1px solid var(--border-gray-color);
}
.carrier-image img:not(.topship-logo) {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.carrier-image img:not(.topship-logo).small {
  width: 2.5rem;
  height: 2.5rem;
}
.carrier-image .topship-logo {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  bottom: -3px;
  right: 0;
}

.box {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  border: 1px solid #eee;
  background-color: white;
}

.row {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.row [class*=col-] {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.total {
  width: 100%;
  padding: 0 2rem;
}
.total .total-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: -2rem;
  margin-right: -2rem;
  border-bottom: 1px solid var(--gray-color);
}
.total .total-section.left-aligned {
  justify-content: flex-start;
}
.total .total-section.left-aligned .total-item {
  text-align: left;
}
.total .total-item {
  text-align: right;
  padding: 0 !important;
}
.total .item-value {
  font-size: 1.15rem;
}
.total .item-value * {
  font-size: 1.15rem;
}

.blank-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.blank-page .blank-img {
  width: 240px;
  height: auto;
}

.fake-material-input-outline {
  height: 40px;
  border: 1px solid var(--border-gray-color);
  border-radius: 5px;
  padding: 10px;
}
.fake-material-input-outline .mat-icon {
  font-size: 1.25rem !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
  cursor: pointer;
}

.fake-material-input-standard {
  border-bottom: 1px solid var(--border-gray-color);
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3px;
}
.fake-material-input-standard .mat-icon {
  font-size: 1.25rem !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
  cursor: pointer;
}
.fake-material-input-standard .fake-material-input-standard-content {
  flex: 1;
  overflow: auto;
  white-space: nowrap;
}
.fake-material-input-standard .fake-material-input-standard-content::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  height: 0;
  background: transparent;
  /* Optional: just make scrollbar invisible */
}
.fake-material-input-standard:hover {
  border-bottom: 2px solid var(--primary-color);
}
.fake-material-input-standard:hover.topship {
  border-bottom: 2px solid var(--topship-color);
}
.fake-material-input-standard.error {
  border-bottom: 2px solid var(--danger-color);
  color: var(--danger-color);
}
.fake-material-input-standard.placeholder {
  font-weight: 400;
  color: #999 !important;
}
.fake-material-input-standard.disabled {
  border-bottom: 1px dashed #ccc !important;
  color: #999 !important;
}
.fake-material-input-standard.disabled:hover {
  border-bottom: 1px dashed #ccc !important;
  color: #999 !important;
}
.fake-material-input-standard.disabled .fake-material-input-standard-content {
  pointer-events: none;
}

.empty {
  padding: 1rem 0;
  text-align: center;
  background: var(--gray-color);
  border: 1px solid var(--border-gray-color);
  border-radius: 5px;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.upload-logo {
  position: relative;
  margin: 0 !important;
}
.upload-logo img {
  cursor: pointer;
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  border: 1px solid #eee;
  border-radius: 50%;
}
.upload-logo .over-logo,
.upload-logo .upload-loading {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  color: #fff;
  font-size: 1.15rem;
}
.upload-logo .over-logo i,
.upload-logo .upload-loading i {
  margin: auto;
}
.upload-logo .over-logo {
  display: none;
}
.upload-logo .upload-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-logo .upload-loading .mat-progress-spinner circle, .upload-logo .upload-loading .mat-spinner circle {
  stroke: #fff !important;
}
.upload-logo .upload-loading i {
  margin: auto;
}
.upload-logo img:hover + .over-logo {
  display: flex;
}
.upload-logo .over-logo:hover {
  display: flex;
}

.min-width-12rem {
  min-width: 12rem;
}

.min-width-15rem {
  min-width: 15rem;
}

.min-width-20rem {
  min-width: 20rem;
}

.min-width-25rem {
  min-width: 25rem;
}

.max-width-20rem {
  max-width: 20rem;
}

.max-width-7rem {
  max-width: 7rem;
}

.show-ranges {
  width: max-content !important;
}

.expire-time-soon {
  color: #e74c3c;
  background-color: rgba(231, 76, 60, 0.1);
  padding: 5px 15px;
  border-radius: 20px;
}

.expire-time {
  color: var(--white-color);
  background-color: var(--danger-color);
  padding: 5px 15px;
  border-radius: 20px;
}

@media screen and (max-width: 767px) {
  .dialog-responsive {
    min-width: 50vw !important;
  }
}

.fix-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fix-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fix-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.close-item {
  background: var(--default-color);
  border-radius: 50%;
  margin: auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-content: center;
  flex-flow: row wrap;
  justify-content: center;
  color: white;
}

::ng-deep .mat-checkbox-layout {
  align-items: center !important;
}

.btn-default {
  background: var(--default-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--default-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-default mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-default > * {
  color: white;
}
.btn-default .mat-progress-spinner circle, .btn-default .mat-spinner circle {
  stroke: white !important;
}
.btn-default.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-default:hover {
  background: var(--default-color);
  color: white;
}
.btn-default:active {
  background: var(--default-color) !important;
}
.btn-default:focus {
  box-shadow: none !important;
}
.btn-default:disabled {
  background: var(--default-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-default.btn-outline {
  background: transparent;
  color: var(--default-color);
}
.btn-default.btn-outline > * {
  color: var(--default-color);
}
.btn-default.btn-outline .mat-progress-spinner circle, .btn-default.btn-outline .mat-spinner circle {
  stroke: var(--default-color) !important;
}
.btn-default.btn-outline.dropdown-toggle:after {
  color: var(--default-color);
}
.btn-default.btn-outline:disabled {
  background: transparent !important;
}
.btn-default.btn-outline:hover {
  color: var(--default-color);
}

@media (max-width: 1024px) {
  .btn-default {
    padding: 0.5rem 1.5rem;
  }
}
.btn-primary {
  background: var(--primary-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--primary-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-primary mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-primary > * {
  color: white;
}
.btn-primary .mat-progress-spinner circle, .btn-primary .mat-spinner circle {
  stroke: white !important;
}
.btn-primary.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-primary:hover {
  background: var(--primary-color);
  color: white;
}
.btn-primary:active {
  background: var(--primary-color) !important;
}
.btn-primary:focus {
  box-shadow: none !important;
}
.btn-primary:disabled {
  background: var(--primary-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-primary.btn-outline {
  background: transparent;
  color: var(--primary-color);
}
.btn-primary.btn-outline > * {
  color: var(--primary-color);
}
.btn-primary.btn-outline .mat-progress-spinner circle, .btn-primary.btn-outline .mat-spinner circle {
  stroke: var(--primary-color) !important;
}
.btn-primary.btn-outline.dropdown-toggle:after {
  color: var(--primary-color);
}
.btn-primary.btn-outline:disabled {
  background: transparent !important;
}
.btn-primary.btn-outline:hover {
  color: var(--primary-color);
}

@media (max-width: 1024px) {
  .btn-primary {
    padding: 0.5rem 1.5rem;
  }
}
.btn-primary-dark {
  background: var(--primary-dark-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--primary-dark-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-primary-dark mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-primary-dark > * {
  color: white;
}
.btn-primary-dark .mat-progress-spinner circle, .btn-primary-dark .mat-spinner circle {
  stroke: white !important;
}
.btn-primary-dark.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-primary-dark:hover {
  background: var(--primary-dark-color);
  color: white;
}
.btn-primary-dark:active {
  background: var(--primary-dark-color) !important;
}
.btn-primary-dark:focus {
  box-shadow: none !important;
}
.btn-primary-dark:disabled {
  background: var(--primary-dark-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-primary-dark.btn-outline {
  background: transparent;
  color: var(--primary-dark-color);
}
.btn-primary-dark.btn-outline > * {
  color: var(--primary-dark-color);
}
.btn-primary-dark.btn-outline .mat-progress-spinner circle, .btn-primary-dark.btn-outline .mat-spinner circle {
  stroke: var(--primary-dark-color) !important;
}
.btn-primary-dark.btn-outline.dropdown-toggle:after {
  color: var(--primary-dark-color);
}
.btn-primary-dark.btn-outline:disabled {
  background: transparent !important;
}
.btn-primary-dark.btn-outline:hover {
  color: var(--primary-dark-color);
}

@media (max-width: 1024px) {
  .btn-primary-dark {
    padding: 0.5rem 1.5rem;
  }
}
.btn-secondary {
  background: var(--secondary-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--secondary-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-secondary mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-secondary > * {
  color: white;
}
.btn-secondary .mat-progress-spinner circle, .btn-secondary .mat-spinner circle {
  stroke: white !important;
}
.btn-secondary.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-secondary:hover {
  background: var(--secondary-color);
  color: white;
}
.btn-secondary:active {
  background: var(--secondary-color) !important;
}
.btn-secondary:focus {
  box-shadow: none !important;
}
.btn-secondary:disabled {
  background: var(--secondary-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-secondary.btn-outline {
  background: transparent;
  color: var(--secondary-color);
}
.btn-secondary.btn-outline > * {
  color: var(--secondary-color);
}
.btn-secondary.btn-outline .mat-progress-spinner circle, .btn-secondary.btn-outline .mat-spinner circle {
  stroke: var(--secondary-color) !important;
}
.btn-secondary.btn-outline.dropdown-toggle:after {
  color: var(--secondary-color);
}
.btn-secondary.btn-outline:disabled {
  background: transparent !important;
}
.btn-secondary.btn-outline:hover {
  color: var(--secondary-color);
}

@media (max-width: 1024px) {
  .btn-secondary {
    padding: 0.5rem 1.5rem;
  }
}
.btn-warning {
  background: var(--warning-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--warning-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-warning mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-warning > * {
  color: white;
}
.btn-warning .mat-progress-spinner circle, .btn-warning .mat-spinner circle {
  stroke: white !important;
}
.btn-warning.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-warning:hover {
  background: var(--warning-color);
  color: white;
}
.btn-warning:active {
  background: var(--warning-color) !important;
}
.btn-warning:focus {
  box-shadow: none !important;
}
.btn-warning:disabled {
  background: var(--warning-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-warning.btn-outline {
  background: transparent;
  color: var(--warning-color);
}
.btn-warning.btn-outline > * {
  color: var(--warning-color);
}
.btn-warning.btn-outline .mat-progress-spinner circle, .btn-warning.btn-outline .mat-spinner circle {
  stroke: var(--warning-color) !important;
}
.btn-warning.btn-outline.dropdown-toggle:after {
  color: var(--warning-color);
}
.btn-warning.btn-outline:disabled {
  background: transparent !important;
}
.btn-warning.btn-outline:hover {
  color: var(--warning-color);
}

@media (max-width: 1024px) {
  .btn-warning {
    padding: 0.5rem 1.5rem;
  }
}
.btn-warning-dark {
  background: var(--warning-dark-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--warning-dark-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-warning-dark mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-warning-dark > * {
  color: white;
}
.btn-warning-dark .mat-progress-spinner circle, .btn-warning-dark .mat-spinner circle {
  stroke: white !important;
}
.btn-warning-dark.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-warning-dark:hover {
  background: var(--warning-dark-color);
  color: white;
}
.btn-warning-dark:active {
  background: var(--warning-dark-color) !important;
}
.btn-warning-dark:focus {
  box-shadow: none !important;
}
.btn-warning-dark:disabled {
  background: var(--warning-dark-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-warning-dark.btn-outline {
  background: transparent;
  color: var(--warning-dark-color);
}
.btn-warning-dark.btn-outline > * {
  color: var(--warning-dark-color);
}
.btn-warning-dark.btn-outline .mat-progress-spinner circle, .btn-warning-dark.btn-outline .mat-spinner circle {
  stroke: var(--warning-dark-color) !important;
}
.btn-warning-dark.btn-outline.dropdown-toggle:after {
  color: var(--warning-dark-color);
}
.btn-warning-dark.btn-outline:disabled {
  background: transparent !important;
}
.btn-warning-dark.btn-outline:hover {
  color: var(--warning-dark-color);
}

@media (max-width: 1024px) {
  .btn-warning-dark {
    padding: 0.5rem 1.5rem;
  }
}
.btn-success {
  background: var(--success-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--success-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-success mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-success > * {
  color: white;
}
.btn-success .mat-progress-spinner circle, .btn-success .mat-spinner circle {
  stroke: white !important;
}
.btn-success.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-success:hover {
  background: var(--success-color);
  color: white;
}
.btn-success:active {
  background: var(--success-color) !important;
}
.btn-success:focus {
  box-shadow: none !important;
}
.btn-success:disabled {
  background: var(--success-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-success.btn-outline {
  background: transparent;
  color: var(--success-color);
}
.btn-success.btn-outline > * {
  color: var(--success-color);
}
.btn-success.btn-outline .mat-progress-spinner circle, .btn-success.btn-outline .mat-spinner circle {
  stroke: var(--success-color) !important;
}
.btn-success.btn-outline.dropdown-toggle:after {
  color: var(--success-color);
}
.btn-success.btn-outline:disabled {
  background: transparent !important;
}
.btn-success.btn-outline:hover {
  color: var(--success-color);
}

@media (max-width: 1024px) {
  .btn-success {
    padding: 0.5rem 1.5rem;
  }
}
.btn-success-dark {
  background: var(--success-dark-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--success-dark-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-success-dark mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-success-dark > * {
  color: white;
}
.btn-success-dark .mat-progress-spinner circle, .btn-success-dark .mat-spinner circle {
  stroke: white !important;
}
.btn-success-dark.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-success-dark:hover {
  background: var(--success-dark-color);
  color: white;
}
.btn-success-dark:active {
  background: var(--success-dark-color) !important;
}
.btn-success-dark:focus {
  box-shadow: none !important;
}
.btn-success-dark:disabled {
  background: var(--success-dark-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-success-dark.btn-outline {
  background: transparent;
  color: var(--success-dark-color);
}
.btn-success-dark.btn-outline > * {
  color: var(--success-dark-color);
}
.btn-success-dark.btn-outline .mat-progress-spinner circle, .btn-success-dark.btn-outline .mat-spinner circle {
  stroke: var(--success-dark-color) !important;
}
.btn-success-dark.btn-outline.dropdown-toggle:after {
  color: var(--success-dark-color);
}
.btn-success-dark.btn-outline:disabled {
  background: transparent !important;
}
.btn-success-dark.btn-outline:hover {
  color: var(--success-dark-color);
}

@media (max-width: 1024px) {
  .btn-success-dark {
    padding: 0.5rem 1.5rem;
  }
}
.btn-danger {
  background: var(--danger-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--danger-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-danger mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-danger > * {
  color: white;
}
.btn-danger .mat-progress-spinner circle, .btn-danger .mat-spinner circle {
  stroke: white !important;
}
.btn-danger.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-danger:hover {
  background: var(--danger-color);
  color: white;
}
.btn-danger:active {
  background: var(--danger-color) !important;
}
.btn-danger:focus {
  box-shadow: none !important;
}
.btn-danger:disabled {
  background: var(--danger-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-danger.btn-outline {
  background: transparent;
  color: var(--danger-color);
}
.btn-danger.btn-outline > * {
  color: var(--danger-color);
}
.btn-danger.btn-outline .mat-progress-spinner circle, .btn-danger.btn-outline .mat-spinner circle {
  stroke: var(--danger-color) !important;
}
.btn-danger.btn-outline.dropdown-toggle:after {
  color: var(--danger-color);
}
.btn-danger.btn-outline:disabled {
  background: transparent !important;
}
.btn-danger.btn-outline:hover {
  color: var(--danger-color);
}

@media (max-width: 1024px) {
  .btn-danger {
    padding: 0.5rem 1.5rem;
  }
}
.btn-danger-dark {
  background: var(--danger-dark-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--danger-dark-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-danger-dark mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-danger-dark > * {
  color: white;
}
.btn-danger-dark .mat-progress-spinner circle, .btn-danger-dark .mat-spinner circle {
  stroke: white !important;
}
.btn-danger-dark.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-danger-dark:hover {
  background: var(--danger-dark-color);
  color: white;
}
.btn-danger-dark:active {
  background: var(--danger-dark-color) !important;
}
.btn-danger-dark:focus {
  box-shadow: none !important;
}
.btn-danger-dark:disabled {
  background: var(--danger-dark-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-danger-dark.btn-outline {
  background: transparent;
  color: var(--danger-dark-color);
}
.btn-danger-dark.btn-outline > * {
  color: var(--danger-dark-color);
}
.btn-danger-dark.btn-outline .mat-progress-spinner circle, .btn-danger-dark.btn-outline .mat-spinner circle {
  stroke: var(--danger-dark-color) !important;
}
.btn-danger-dark.btn-outline.dropdown-toggle:after {
  color: var(--danger-dark-color);
}
.btn-danger-dark.btn-outline:disabled {
  background: transparent !important;
}
.btn-danger-dark.btn-outline:hover {
  color: var(--danger-dark-color);
}

@media (max-width: 1024px) {
  .btn-danger-dark {
    padding: 0.5rem 1.5rem;
  }
}
.btn-light {
  background: var(--light-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--light-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-light mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-light > * {
  color: white;
}
.btn-light .mat-progress-spinner circle, .btn-light .mat-spinner circle {
  stroke: white !important;
}
.btn-light.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-light:hover {
  background: var(--light-color);
  color: white;
}
.btn-light:active {
  background: var(--light-color) !important;
}
.btn-light:focus {
  box-shadow: none !important;
}
.btn-light:disabled {
  background: var(--light-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-light.btn-outline {
  background: transparent;
  color: var(--light-color);
}
.btn-light.btn-outline > * {
  color: var(--light-color);
}
.btn-light.btn-outline .mat-progress-spinner circle, .btn-light.btn-outline .mat-spinner circle {
  stroke: var(--light-color) !important;
}
.btn-light.btn-outline.dropdown-toggle:after {
  color: var(--light-color);
}
.btn-light.btn-outline:disabled {
  background: transparent !important;
}
.btn-light.btn-outline:hover {
  color: var(--light-color);
}

@media (max-width: 1024px) {
  .btn-light {
    padding: 0.5rem 1.5rem;
  }
}
.btn-dark {
  background: var(--dark-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--dark-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-dark mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-dark > * {
  color: white;
}
.btn-dark .mat-progress-spinner circle, .btn-dark .mat-spinner circle {
  stroke: white !important;
}
.btn-dark.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-dark:hover {
  background: var(--dark-color);
  color: white;
}
.btn-dark:active {
  background: var(--dark-color) !important;
}
.btn-dark:focus {
  box-shadow: none !important;
}
.btn-dark:disabled {
  background: var(--dark-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-dark.btn-outline {
  background: transparent;
  color: var(--dark-color);
}
.btn-dark.btn-outline > * {
  color: var(--dark-color);
}
.btn-dark.btn-outline .mat-progress-spinner circle, .btn-dark.btn-outline .mat-spinner circle {
  stroke: var(--dark-color) !important;
}
.btn-dark.btn-outline.dropdown-toggle:after {
  color: var(--dark-color);
}
.btn-dark.btn-outline:disabled {
  background: transparent !important;
}
.btn-dark.btn-outline:hover {
  color: var(--dark-color);
}

@media (max-width: 1024px) {
  .btn-dark {
    padding: 0.5rem 1.5rem;
  }
}
.btn-tertiary {
  background: var(--tertiary-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--tertiary-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-tertiary mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-tertiary > * {
  color: white;
}
.btn-tertiary .mat-progress-spinner circle, .btn-tertiary .mat-spinner circle {
  stroke: white !important;
}
.btn-tertiary.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-tertiary:hover {
  background: var(--tertiary-color);
  color: white;
}
.btn-tertiary:active {
  background: var(--tertiary-color) !important;
}
.btn-tertiary:focus {
  box-shadow: none !important;
}
.btn-tertiary:disabled {
  background: var(--tertiary-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-tertiary.btn-outline {
  background: transparent;
  color: var(--tertiary-color);
}
.btn-tertiary.btn-outline > * {
  color: var(--tertiary-color);
}
.btn-tertiary.btn-outline .mat-progress-spinner circle, .btn-tertiary.btn-outline .mat-spinner circle {
  stroke: var(--tertiary-color) !important;
}
.btn-tertiary.btn-outline.dropdown-toggle:after {
  color: var(--tertiary-color);
}
.btn-tertiary.btn-outline:disabled {
  background: transparent !important;
}
.btn-tertiary.btn-outline:hover {
  color: var(--tertiary-color);
}

@media (max-width: 1024px) {
  .btn-tertiary {
    padding: 0.5rem 1.5rem;
  }
}
.btn-gray {
  background: var(--gray-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--gray-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-gray mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-gray > * {
  color: white;
}
.btn-gray .mat-progress-spinner circle, .btn-gray .mat-spinner circle {
  stroke: white !important;
}
.btn-gray.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-gray:hover {
  background: var(--gray-color);
  color: white;
}
.btn-gray:active {
  background: var(--gray-color) !important;
}
.btn-gray:focus {
  box-shadow: none !important;
}
.btn-gray:disabled {
  background: var(--gray-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-gray.btn-outline {
  background: transparent;
  color: var(--gray-color);
}
.btn-gray.btn-outline > * {
  color: var(--gray-color);
}
.btn-gray.btn-outline .mat-progress-spinner circle, .btn-gray.btn-outline .mat-spinner circle {
  stroke: var(--gray-color) !important;
}
.btn-gray.btn-outline.dropdown-toggle:after {
  color: var(--gray-color);
}
.btn-gray.btn-outline:disabled {
  background: transparent !important;
}
.btn-gray.btn-outline:hover {
  color: var(--gray-color);
}

@media (max-width: 1024px) {
  .btn-gray {
    padding: 0.5rem 1.5rem;
  }
}
.btn-yellow {
  background: var(--yellow-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--yellow-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-yellow mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-yellow > * {
  color: white;
}
.btn-yellow .mat-progress-spinner circle, .btn-yellow .mat-spinner circle {
  stroke: white !important;
}
.btn-yellow.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-yellow:hover {
  background: var(--yellow-color);
  color: white;
}
.btn-yellow:active {
  background: var(--yellow-color) !important;
}
.btn-yellow:focus {
  box-shadow: none !important;
}
.btn-yellow:disabled {
  background: var(--yellow-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-yellow.btn-outline {
  background: transparent;
  color: var(--yellow-color);
}
.btn-yellow.btn-outline > * {
  color: var(--yellow-color);
}
.btn-yellow.btn-outline .mat-progress-spinner circle, .btn-yellow.btn-outline .mat-spinner circle {
  stroke: var(--yellow-color) !important;
}
.btn-yellow.btn-outline.dropdown-toggle:after {
  color: var(--yellow-color);
}
.btn-yellow.btn-outline:disabled {
  background: transparent !important;
}
.btn-yellow.btn-outline:hover {
  color: var(--yellow-color);
}

@media (max-width: 1024px) {
  .btn-yellow {
    padding: 0.5rem 1.5rem;
  }
}
.btn-turquoise {
  background: var(--turquoise-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--turquoise-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-turquoise mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-turquoise > * {
  color: white;
}
.btn-turquoise .mat-progress-spinner circle, .btn-turquoise .mat-spinner circle {
  stroke: white !important;
}
.btn-turquoise.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-turquoise:hover {
  background: var(--turquoise-color);
  color: white;
}
.btn-turquoise:active {
  background: var(--turquoise-color) !important;
}
.btn-turquoise:focus {
  box-shadow: none !important;
}
.btn-turquoise:disabled {
  background: var(--turquoise-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-turquoise.btn-outline {
  background: transparent;
  color: var(--turquoise-color);
}
.btn-turquoise.btn-outline > * {
  color: var(--turquoise-color);
}
.btn-turquoise.btn-outline .mat-progress-spinner circle, .btn-turquoise.btn-outline .mat-spinner circle {
  stroke: var(--turquoise-color) !important;
}
.btn-turquoise.btn-outline.dropdown-toggle:after {
  color: var(--turquoise-color);
}
.btn-turquoise.btn-outline:disabled {
  background: transparent !important;
}
.btn-turquoise.btn-outline:hover {
  color: var(--turquoise-color);
}

@media (max-width: 1024px) {
  .btn-turquoise {
    padding: 0.5rem 1.5rem;
  }
}
.btn-blue {
  background: var(--blue-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--blue-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-blue mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-blue > * {
  color: white;
}
.btn-blue .mat-progress-spinner circle, .btn-blue .mat-spinner circle {
  stroke: white !important;
}
.btn-blue.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-blue:hover {
  background: var(--blue-color);
  color: white;
}
.btn-blue:active {
  background: var(--blue-color) !important;
}
.btn-blue:focus {
  box-shadow: none !important;
}
.btn-blue:disabled {
  background: var(--blue-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-blue.btn-outline {
  background: transparent;
  color: var(--blue-color);
}
.btn-blue.btn-outline > * {
  color: var(--blue-color);
}
.btn-blue.btn-outline .mat-progress-spinner circle, .btn-blue.btn-outline .mat-spinner circle {
  stroke: var(--blue-color) !important;
}
.btn-blue.btn-outline.dropdown-toggle:after {
  color: var(--blue-color);
}
.btn-blue.btn-outline:disabled {
  background: transparent !important;
}
.btn-blue.btn-outline:hover {
  color: var(--blue-color);
}

@media (max-width: 1024px) {
  .btn-blue {
    padding: 0.5rem 1.5rem;
  }
}
.btn-border-gray {
  background: var(--border-gray-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--border-gray-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-border-gray mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-border-gray > * {
  color: white;
}
.btn-border-gray .mat-progress-spinner circle, .btn-border-gray .mat-spinner circle {
  stroke: white !important;
}
.btn-border-gray.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-border-gray:hover {
  background: var(--border-gray-color);
  color: white;
}
.btn-border-gray:active {
  background: var(--border-gray-color) !important;
}
.btn-border-gray:focus {
  box-shadow: none !important;
}
.btn-border-gray:disabled {
  background: var(--border-gray-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-border-gray.btn-outline {
  background: transparent;
  color: var(--border-gray-color);
}
.btn-border-gray.btn-outline > * {
  color: var(--border-gray-color);
}
.btn-border-gray.btn-outline .mat-progress-spinner circle, .btn-border-gray.btn-outline .mat-spinner circle {
  stroke: var(--border-gray-color) !important;
}
.btn-border-gray.btn-outline.dropdown-toggle:after {
  color: var(--border-gray-color);
}
.btn-border-gray.btn-outline:disabled {
  background: transparent !important;
}
.btn-border-gray.btn-outline:hover {
  color: var(--border-gray-color);
}

@media (max-width: 1024px) {
  .btn-border-gray {
    padding: 0.5rem 1.5rem;
  }
}
.btn-lighter-gray {
  background: var(--lighter-gray-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--lighter-gray-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-lighter-gray mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-lighter-gray > * {
  color: white;
}
.btn-lighter-gray .mat-progress-spinner circle, .btn-lighter-gray .mat-spinner circle {
  stroke: white !important;
}
.btn-lighter-gray.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-lighter-gray:hover {
  background: var(--lighter-gray-color);
  color: white;
}
.btn-lighter-gray:active {
  background: var(--lighter-gray-color) !important;
}
.btn-lighter-gray:focus {
  box-shadow: none !important;
}
.btn-lighter-gray:disabled {
  background: var(--lighter-gray-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-lighter-gray.btn-outline {
  background: transparent;
  color: var(--lighter-gray-color);
}
.btn-lighter-gray.btn-outline > * {
  color: var(--lighter-gray-color);
}
.btn-lighter-gray.btn-outline .mat-progress-spinner circle, .btn-lighter-gray.btn-outline .mat-spinner circle {
  stroke: var(--lighter-gray-color) !important;
}
.btn-lighter-gray.btn-outline.dropdown-toggle:after {
  color: var(--lighter-gray-color);
}
.btn-lighter-gray.btn-outline:disabled {
  background: transparent !important;
}
.btn-lighter-gray.btn-outline:hover {
  color: var(--lighter-gray-color);
}

@media (max-width: 1024px) {
  .btn-lighter-gray {
    padding: 0.5rem 1.5rem;
  }
}
.btn-white {
  background: var(--white-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--white-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-white mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-white > * {
  color: white;
}
.btn-white .mat-progress-spinner circle, .btn-white .mat-spinner circle {
  stroke: white !important;
}
.btn-white.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-white:hover {
  background: var(--white-color);
  color: white;
}
.btn-white:active {
  background: var(--white-color) !important;
}
.btn-white:focus {
  box-shadow: none !important;
}
.btn-white:disabled {
  background: var(--white-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-white.btn-outline {
  background: transparent;
  color: var(--white-color);
}
.btn-white.btn-outline > * {
  color: var(--white-color);
}
.btn-white.btn-outline .mat-progress-spinner circle, .btn-white.btn-outline .mat-spinner circle {
  stroke: var(--white-color) !important;
}
.btn-white.btn-outline.dropdown-toggle:after {
  color: var(--white-color);
}
.btn-white.btn-outline:disabled {
  background: transparent !important;
}
.btn-white.btn-outline:hover {
  color: var(--white-color);
}

@media (max-width: 1024px) {
  .btn-white {
    padding: 0.5rem 1.5rem;
  }
}
.btn-topship {
  background: var(--topship-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--topship-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-topship mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-topship > * {
  color: white;
}
.btn-topship .mat-progress-spinner circle, .btn-topship .mat-spinner circle {
  stroke: white !important;
}
.btn-topship.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-topship:hover {
  background: var(--topship-color);
  color: white;
}
.btn-topship:active {
  background: var(--topship-color) !important;
}
.btn-topship:focus {
  box-shadow: none !important;
}
.btn-topship:disabled {
  background: var(--topship-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-topship.btn-outline {
  background: transparent;
  color: var(--topship-color);
}
.btn-topship.btn-outline > * {
  color: var(--topship-color);
}
.btn-topship.btn-outline .mat-progress-spinner circle, .btn-topship.btn-outline .mat-spinner circle {
  stroke: var(--topship-color) !important;
}
.btn-topship.btn-outline.dropdown-toggle:after {
  color: var(--topship-color);
}
.btn-topship.btn-outline:disabled {
  background: transparent !important;
}
.btn-topship.btn-outline:hover {
  color: var(--topship-color);
}

@media (max-width: 1024px) {
  .btn-topship {
    padding: 0.5rem 1.5rem;
  }
}
.btn-black {
  background: var(--black-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--black-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-black mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-black > * {
  color: white;
}
.btn-black .mat-progress-spinner circle, .btn-black .mat-spinner circle {
  stroke: white !important;
}
.btn-black.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-black:hover {
  background: var(--black-color);
  color: white;
}
.btn-black:active {
  background: var(--black-color) !important;
}
.btn-black:focus {
  box-shadow: none !important;
}
.btn-black:disabled {
  background: var(--black-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-black.btn-outline {
  background: transparent;
  color: var(--black-color);
}
.btn-black.btn-outline > * {
  color: var(--black-color);
}
.btn-black.btn-outline .mat-progress-spinner circle, .btn-black.btn-outline .mat-spinner circle {
  stroke: var(--black-color) !important;
}
.btn-black.btn-outline.dropdown-toggle:after {
  color: var(--black-color);
}
.btn-black.btn-outline:disabled {
  background: transparent !important;
}
.btn-black.btn-outline:hover {
  color: var(--black-color);
}

@media (max-width: 1024px) {
  .btn-black {
    padding: 0.5rem 1.5rem;
  }
}
.btn-neutral {
  background: var(--neutral-color);
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--neutral-color) !important;
  padding: 0.5rem 2.5rem;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.btn-neutral mat-icon {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}
.btn-neutral > * {
  color: white;
}
.btn-neutral .mat-progress-spinner circle, .btn-neutral .mat-spinner circle {
  stroke: white !important;
}
.btn-neutral.dropdown-toggle:after {
  color: white;
  vertical-align: middle;
}
.btn-neutral:hover {
  background: var(--neutral-color);
  color: white;
}
.btn-neutral:active {
  background: var(--neutral-color) !important;
}
.btn-neutral:focus {
  box-shadow: none !important;
}
.btn-neutral:disabled {
  background: var(--neutral-color) !important;
  cursor: not-allowed;
  opacity: 0.8;
}
.btn-neutral.btn-outline {
  background: transparent;
  color: var(--neutral-color);
}
.btn-neutral.btn-outline > * {
  color: var(--neutral-color);
}
.btn-neutral.btn-outline .mat-progress-spinner circle, .btn-neutral.btn-outline .mat-spinner circle {
  stroke: var(--neutral-color) !important;
}
.btn-neutral.btn-outline.dropdown-toggle:after {
  color: var(--neutral-color);
}
.btn-neutral.btn-outline:disabled {
  background: transparent !important;
}
.btn-neutral.btn-outline:hover {
  color: var(--neutral-color);
}

@media (max-width: 1024px) {
  .btn-neutral {
    padding: 0.5rem 1.5rem;
  }
}
.btn-full {
  width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 16px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  transition: 0.2s;
}

.slider-toggle:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 1px;
  background-color: #ccc;
  transition: 0.2s;
}

input:checked + .slider-toggle {
  background-color: white;
  border: 1px solid var(--primary-color);
}

input:focus + .slider-toggle {
  box-shadow: 0 0 1px white;
}

input:checked + .slider-toggle:before {
  transform: translateX(20px);
  background-color: var(--primary-color);
}

/* Rounded sliders */
.slider-toggle.round {
  border-radius: 34px;
  border: 1px solid #ccc;
}

.slider-toggle.round:before {
  border-radius: 50%;
}

.topship-toggle input:checked + .slider-toggle {
  border: 1px solid #E67E22;
}
.topship-toggle input:checked + .slider-toggle:before {
  background-color: #E67E22;
}

.icon-dropdown {
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  padding: 3px 4px;
  width: 18px;
  height: 18px;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:active {
  background: none !important;
}

.show > .btn-primary.dropdown-toggle {
  color: unset;
  background-color: unset;
  border-color: unset;
}

.close-button-with-icon {
  border: 1px solid #856404;
  border-radius: 20px;
  padding: 4px 20px;
  cursor: pointer;
}

.text-default {
  color: var(--default-color) !important;
}
.text-default .fa {
  color: var(--default-color) !important;
}
.text-default .external-link-icon {
  color: var(--default-color) !important;
}
.text-default .mat-progress-spinner circle, .text-default .mat-spinner circle {
  stroke: var(--default-color) !important;
}
.text-default.mat-progress-spinner circle, .text-default.mat-spinner circle {
  stroke: var(--default-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}
.text-primary .fa {
  color: var(--primary-color) !important;
}
.text-primary .external-link-icon {
  color: var(--primary-color) !important;
}
.text-primary .mat-progress-spinner circle, .text-primary .mat-spinner circle {
  stroke: var(--primary-color) !important;
}
.text-primary.mat-progress-spinner circle, .text-primary.mat-spinner circle {
  stroke: var(--primary-color) !important;
}

.text-primary-dark {
  color: var(--primary-dark-color) !important;
}
.text-primary-dark .fa {
  color: var(--primary-dark-color) !important;
}
.text-primary-dark .external-link-icon {
  color: var(--primary-dark-color) !important;
}
.text-primary-dark .mat-progress-spinner circle, .text-primary-dark .mat-spinner circle {
  stroke: var(--primary-dark-color) !important;
}
.text-primary-dark.mat-progress-spinner circle, .text-primary-dark.mat-spinner circle {
  stroke: var(--primary-dark-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}
.text-secondary .fa {
  color: var(--secondary-color) !important;
}
.text-secondary .external-link-icon {
  color: var(--secondary-color) !important;
}
.text-secondary .mat-progress-spinner circle, .text-secondary .mat-spinner circle {
  stroke: var(--secondary-color) !important;
}
.text-secondary.mat-progress-spinner circle, .text-secondary.mat-spinner circle {
  stroke: var(--secondary-color) !important;
}

.text-warning {
  color: var(--warning-color) !important;
}
.text-warning .fa {
  color: var(--warning-color) !important;
}
.text-warning .external-link-icon {
  color: var(--warning-color) !important;
}
.text-warning .mat-progress-spinner circle, .text-warning .mat-spinner circle {
  stroke: var(--warning-color) !important;
}
.text-warning.mat-progress-spinner circle, .text-warning.mat-spinner circle {
  stroke: var(--warning-color) !important;
}

.text-warning-dark {
  color: var(--warning-dark-color) !important;
}
.text-warning-dark .fa {
  color: var(--warning-dark-color) !important;
}
.text-warning-dark .external-link-icon {
  color: var(--warning-dark-color) !important;
}
.text-warning-dark .mat-progress-spinner circle, .text-warning-dark .mat-spinner circle {
  stroke: var(--warning-dark-color) !important;
}
.text-warning-dark.mat-progress-spinner circle, .text-warning-dark.mat-spinner circle {
  stroke: var(--warning-dark-color) !important;
}

.text-success {
  color: var(--success-color) !important;
}
.text-success .fa {
  color: var(--success-color) !important;
}
.text-success .external-link-icon {
  color: var(--success-color) !important;
}
.text-success .mat-progress-spinner circle, .text-success .mat-spinner circle {
  stroke: var(--success-color) !important;
}
.text-success.mat-progress-spinner circle, .text-success.mat-spinner circle {
  stroke: var(--success-color) !important;
}

.text-success-dark {
  color: var(--success-dark-color) !important;
}
.text-success-dark .fa {
  color: var(--success-dark-color) !important;
}
.text-success-dark .external-link-icon {
  color: var(--success-dark-color) !important;
}
.text-success-dark .mat-progress-spinner circle, .text-success-dark .mat-spinner circle {
  stroke: var(--success-dark-color) !important;
}
.text-success-dark.mat-progress-spinner circle, .text-success-dark.mat-spinner circle {
  stroke: var(--success-dark-color) !important;
}

.text-danger {
  color: var(--danger-color) !important;
}
.text-danger .fa {
  color: var(--danger-color) !important;
}
.text-danger .external-link-icon {
  color: var(--danger-color) !important;
}
.text-danger .mat-progress-spinner circle, .text-danger .mat-spinner circle {
  stroke: var(--danger-color) !important;
}
.text-danger.mat-progress-spinner circle, .text-danger.mat-spinner circle {
  stroke: var(--danger-color) !important;
}

.text-danger-dark {
  color: var(--danger-dark-color) !important;
}
.text-danger-dark .fa {
  color: var(--danger-dark-color) !important;
}
.text-danger-dark .external-link-icon {
  color: var(--danger-dark-color) !important;
}
.text-danger-dark .mat-progress-spinner circle, .text-danger-dark .mat-spinner circle {
  stroke: var(--danger-dark-color) !important;
}
.text-danger-dark.mat-progress-spinner circle, .text-danger-dark.mat-spinner circle {
  stroke: var(--danger-dark-color) !important;
}

.text-light {
  color: var(--light-color) !important;
}
.text-light .fa {
  color: var(--light-color) !important;
}
.text-light .external-link-icon {
  color: var(--light-color) !important;
}
.text-light .mat-progress-spinner circle, .text-light .mat-spinner circle {
  stroke: var(--light-color) !important;
}
.text-light.mat-progress-spinner circle, .text-light.mat-spinner circle {
  stroke: var(--light-color) !important;
}

.text-dark {
  color: var(--dark-color) !important;
}
.text-dark .fa {
  color: var(--dark-color) !important;
}
.text-dark .external-link-icon {
  color: var(--dark-color) !important;
}
.text-dark .mat-progress-spinner circle, .text-dark .mat-spinner circle {
  stroke: var(--dark-color) !important;
}
.text-dark.mat-progress-spinner circle, .text-dark.mat-spinner circle {
  stroke: var(--dark-color) !important;
}

.text-tertiary {
  color: var(--tertiary-color) !important;
}
.text-tertiary .fa {
  color: var(--tertiary-color) !important;
}
.text-tertiary .external-link-icon {
  color: var(--tertiary-color) !important;
}
.text-tertiary .mat-progress-spinner circle, .text-tertiary .mat-spinner circle {
  stroke: var(--tertiary-color) !important;
}
.text-tertiary.mat-progress-spinner circle, .text-tertiary.mat-spinner circle {
  stroke: var(--tertiary-color) !important;
}

.text-gray {
  color: var(--gray-color) !important;
}
.text-gray .fa {
  color: var(--gray-color) !important;
}
.text-gray .external-link-icon {
  color: var(--gray-color) !important;
}
.text-gray .mat-progress-spinner circle, .text-gray .mat-spinner circle {
  stroke: var(--gray-color) !important;
}
.text-gray.mat-progress-spinner circle, .text-gray.mat-spinner circle {
  stroke: var(--gray-color) !important;
}

.text-yellow {
  color: var(--yellow-color) !important;
}
.text-yellow .fa {
  color: var(--yellow-color) !important;
}
.text-yellow .external-link-icon {
  color: var(--yellow-color) !important;
}
.text-yellow .mat-progress-spinner circle, .text-yellow .mat-spinner circle {
  stroke: var(--yellow-color) !important;
}
.text-yellow.mat-progress-spinner circle, .text-yellow.mat-spinner circle {
  stroke: var(--yellow-color) !important;
}

.text-turquoise {
  color: var(--turquoise-color) !important;
}
.text-turquoise .fa {
  color: var(--turquoise-color) !important;
}
.text-turquoise .external-link-icon {
  color: var(--turquoise-color) !important;
}
.text-turquoise .mat-progress-spinner circle, .text-turquoise .mat-spinner circle {
  stroke: var(--turquoise-color) !important;
}
.text-turquoise.mat-progress-spinner circle, .text-turquoise.mat-spinner circle {
  stroke: var(--turquoise-color) !important;
}

.text-blue {
  color: var(--blue-color) !important;
}
.text-blue .fa {
  color: var(--blue-color) !important;
}
.text-blue .external-link-icon {
  color: var(--blue-color) !important;
}
.text-blue .mat-progress-spinner circle, .text-blue .mat-spinner circle {
  stroke: var(--blue-color) !important;
}
.text-blue.mat-progress-spinner circle, .text-blue.mat-spinner circle {
  stroke: var(--blue-color) !important;
}

.text-border-gray {
  color: var(--border-gray-color) !important;
}
.text-border-gray .fa {
  color: var(--border-gray-color) !important;
}
.text-border-gray .external-link-icon {
  color: var(--border-gray-color) !important;
}
.text-border-gray .mat-progress-spinner circle, .text-border-gray .mat-spinner circle {
  stroke: var(--border-gray-color) !important;
}
.text-border-gray.mat-progress-spinner circle, .text-border-gray.mat-spinner circle {
  stroke: var(--border-gray-color) !important;
}

.text-lighter-gray {
  color: var(--lighter-gray-color) !important;
}
.text-lighter-gray .fa {
  color: var(--lighter-gray-color) !important;
}
.text-lighter-gray .external-link-icon {
  color: var(--lighter-gray-color) !important;
}
.text-lighter-gray .mat-progress-spinner circle, .text-lighter-gray .mat-spinner circle {
  stroke: var(--lighter-gray-color) !important;
}
.text-lighter-gray.mat-progress-spinner circle, .text-lighter-gray.mat-spinner circle {
  stroke: var(--lighter-gray-color) !important;
}

.text-white {
  color: var(--white-color) !important;
}
.text-white .fa {
  color: var(--white-color) !important;
}
.text-white .external-link-icon {
  color: var(--white-color) !important;
}
.text-white .mat-progress-spinner circle, .text-white .mat-spinner circle {
  stroke: var(--white-color) !important;
}
.text-white.mat-progress-spinner circle, .text-white.mat-spinner circle {
  stroke: var(--white-color) !important;
}

.text-topship {
  color: var(--topship-color) !important;
}
.text-topship .fa {
  color: var(--topship-color) !important;
}
.text-topship .external-link-icon {
  color: var(--topship-color) !important;
}
.text-topship .mat-progress-spinner circle, .text-topship .mat-spinner circle {
  stroke: var(--topship-color) !important;
}
.text-topship.mat-progress-spinner circle, .text-topship.mat-spinner circle {
  stroke: var(--topship-color) !important;
}

.text-black {
  color: var(--black-color) !important;
}
.text-black .fa {
  color: var(--black-color) !important;
}
.text-black .external-link-icon {
  color: var(--black-color) !important;
}
.text-black .mat-progress-spinner circle, .text-black .mat-spinner circle {
  stroke: var(--black-color) !important;
}
.text-black.mat-progress-spinner circle, .text-black.mat-spinner circle {
  stroke: var(--black-color) !important;
}

.text-neutral {
  color: var(--neutral-color) !important;
}
.text-neutral .fa {
  color: var(--neutral-color) !important;
}
.text-neutral .external-link-icon {
  color: var(--neutral-color) !important;
}
.text-neutral .mat-progress-spinner circle, .text-neutral .mat-spinner circle {
  stroke: var(--neutral-color) !important;
}
.text-neutral.mat-progress-spinner circle, .text-neutral.mat-spinner circle {
  stroke: var(--neutral-color) !important;
}

.text-small {
  font-size: 0.7rem;
}

.text-big {
  font-size: 1.2rem;
}

.text-bigger {
  font-size: 1.4rem;
}

.text-large {
  font-size: 1.8rem;
}

.text-larger {
  font-size: 2rem;
}

.text-bold {
  font-weight: 600;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.sub-state {
  font-size: 0.9rem;
  margin-top: 0.33rem;
  background: rgba(var(--default-color-rgb), 0.2);
  border-radius: 5px;
  padding: 0.25rem 1rem;
}

.group-title {
  color: #212529;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #E5E5E5;
  line-height: 0.1em;
  margin: 20px 0 20px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.group-title > span {
  background: #fff;
  padding: 0 10px 0 0;
}

.fs-medium {
  font-size: 1.2rem;
}

.font-8 {
  font-size: 8px;
}

.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

label {
  margin-bottom: 0;
}

.etop-badge {
  background: #fdfdfd;
  min-width: 35px;
  line-height: inherit !important;
  border-radius: 5px;
  margin: 2px 0;
  padding: 3px 10px;
  font-size: 0.8rem;
  display: inline-block;
  border: 1px solid #f1f1f1;
}

.badge-full {
  width: 100%;
}

.badge-default {
  color: #999999;
}

.badge-primary {
  color: #1D9AD6;
}

.badge-primary-dark {
  color: #0C85A1;
}

.badge-secondary {
  color: #33B6D4;
}

.badge-warning {
  color: #F7941E;
}

.badge-warning-dark {
  color: #E78005;
}

.badge-success {
  color: #2ECC71;
}

.badge-success-dark {
  color: #11AF54;
}

.badge-danger {
  color: #e74c3c;
}

.badge-danger-dark {
  color: #C92A1A;
}

.badge-light {
  color: #888888;
}

.badge-dark {
  color: #444444;
}

.badge-tertiary {
  color: #9b59b6;
}

.badge-gray {
  color: #f5f5f5;
}

.badge-yellow {
  color: #f1c40f;
}

.badge-turquoise {
  color: #1abc9c;
}

.badge-blue {
  color: #3498db;
}

.badge-border-gray {
  color: #eee;
}

.badge-lighter-gray {
  color: #fdfdfd;
}

.badge-white {
  color: #fff;
}

.badge-topship {
  color: #e67e22;
}

.badge-black {
  color: #000;
}

.badge-neutral {
  color: #34495e;
}

.badge-default {
  color: #999999;
}

.badge-new {
  color: #999999;
}

.badge-created {
  color: #999999;
}

.badge-unknown {
  color: #999999;
}

.badge-processing {
  color: #e67e22;
}

.badge-picking {
  color: #e67e22;
}

.badge-holding {
  color: #e67e22;
}

.badge-delivering {
  color: #e67e22;
}

.badge-returning {
  color: #e67e22;
}

.badge-undeliverable {
  color: #e74c3c;
}

.badge-cancelled {
  color: #e74c3c;
}

.badge-error {
  color: #e74c3c;
}

.badge-fail {
  color: #e74c3c;
}

.badge-delivered {
  color: #2ECC71;
}

.badge-success {
  color: #2ECC71;
}

.badge-received {
  color: #9b59b6;
}

.badge-returned {
  color: #9b59b6;
}

.badge-ignore {
  color: #34495e;
}

.badge-Z {
  color: #999999;
}

.badge-N {
  color: #e74c3c;
}

.badge-S {
  color: #e67e22;
}

.badge-NS {
  color: #9b59b6;
}

.badge-P {
  color: #2ECC71;
}

.badge-ticket-Open {
  color: "primary";
}

.badge-ticket-Closed {
  color: "secondary";
}

.badge-product {
  background-color: rgba(var(--lighter-gray-color-rgb), 0.6);
  border: 1px solid rgba(var(--lighter-gray-color-rgb), 1);
  padding: 2px 10px;
  border-radius: 5px;
  display: inline-block;
  margin: 2px 0;
  font-size: 0.8rem;
  font-weight: 500;
}

.badge-border {
  border: 1px solid rgba(var(--gray-color-rgb), 1);
}

.noborder {
  border: none !important;
}

.read-only-badge {
  padding: 2px 10px;
  font-size: 1rem;
  display: inline-flex;
}

.header-tab-badge {
  color: #fff;
  padding: 3px 13px;
  border-radius: 20px;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.avatar {
  width: 35px;
  height: 35px;
  border: 1px solid var(--border-gray-color);
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.avatar-small {
  width: 32px;
  height: 32px;
}

.avatar-inactive {
  border-color: var(--border-gray-color);
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: none;
}
.form-control.error {
  border-color: var(--danger-color);
}

input[type=checkbox].input-custom {
  width: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 18px;
  border: 1px solid #95a5a6;
  border-radius: 3px;
  vertical-align: middle;
  cursor: pointer;
}

input[type=checkbox].input-custom:focus {
  outline: none !important;
}

input[type=checkbox].input-custom:checked {
  background-color: #fff;
}

input[type=checkbox].input-custom:checked:after {
  width: 6px;
  height: 9px;
  margin-left: 5px;
  margin-top: 3px;
  border: solid #6e6e6e;
  border-width: 0 2px 2px 0;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  display: inline-block;
}

.mat-form-field {
  width: 100% !important;
}

.mat-form-field-infix textarea {
  padding: 0 5px !important;
  line-height: 16px;
}

.input-material {
  border-radius: 5px;
  outline: none;
  padding: 3px 10px;
  color: #2c3e50;
  border: 1px solid #e6e6e6;
}
.input-material::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.input-material:focus {
  border: 1px solid #33b7d5;
}

.input-pos {
  border: none;
  border-bottom: 1px solid #ddd;
  outline: none;
  width: 100%;
  border-radius: 0;
}

input {
  color: #6e6e6e;
}
input::placeholder {
  font-weight: 400;
  opacity: 0.8;
}

.input-full {
  width: 100%;
}

.bg-default {
  background-color: rgba(var(--default-color-rgb), 1) !important;
}

.bg-default-light {
  background-color: rgba(var(--default-color-rgb), 0.1) !important;
}

.bg-primary {
  background-color: rgba(var(--primary-color-rgb), 1) !important;
}

.bg-primary-light {
  background-color: rgba(var(--primary-color-rgb), 0.1) !important;
}

.bg-primary-dark {
  background-color: rgba(var(--primary-dark-color-rgb), 1) !important;
}

.bg-primary-dark-light {
  background-color: rgba(var(--primary-dark-color-rgb), 0.1) !important;
}

.bg-secondary {
  background-color: rgba(var(--secondary-color-rgb), 1) !important;
}

.bg-secondary-light {
  background-color: rgba(var(--secondary-color-rgb), 0.1) !important;
}

.bg-warning {
  background-color: rgba(var(--warning-color-rgb), 1) !important;
}

.bg-warning-light {
  background-color: rgba(var(--warning-color-rgb), 0.1) !important;
}

.bg-warning-dark {
  background-color: rgba(var(--warning-dark-color-rgb), 1) !important;
}

.bg-warning-dark-light {
  background-color: rgba(var(--warning-dark-color-rgb), 0.1) !important;
}

.bg-success {
  background-color: rgba(var(--success-color-rgb), 1) !important;
}

.bg-success-light {
  background-color: rgba(var(--success-color-rgb), 0.1) !important;
}

.bg-success-dark {
  background-color: rgba(var(--success-dark-color-rgb), 1) !important;
}

.bg-success-dark-light {
  background-color: rgba(var(--success-dark-color-rgb), 0.1) !important;
}

.bg-danger {
  background-color: rgba(var(--danger-color-rgb), 1) !important;
}

.bg-danger-light {
  background-color: rgba(var(--danger-color-rgb), 0.1) !important;
}

.bg-danger-dark {
  background-color: rgba(var(--danger-dark-color-rgb), 1) !important;
}

.bg-danger-dark-light {
  background-color: rgba(var(--danger-dark-color-rgb), 0.1) !important;
}

.bg-light {
  background-color: rgba(var(--light-color-rgb), 1) !important;
}

.bg-light-light {
  background-color: rgba(var(--light-color-rgb), 0.1) !important;
}

.bg-dark {
  background-color: rgba(var(--dark-color-rgb), 1) !important;
}

.bg-dark-light {
  background-color: rgba(var(--dark-color-rgb), 0.1) !important;
}

.bg-tertiary {
  background-color: rgba(var(--tertiary-color-rgb), 1) !important;
}

.bg-tertiary-light {
  background-color: rgba(var(--tertiary-color-rgb), 0.1) !important;
}

.bg-gray {
  background-color: rgba(var(--gray-color-rgb), 1) !important;
}

.bg-gray-light {
  background-color: rgba(var(--gray-color-rgb), 0.1) !important;
}

.bg-yellow {
  background-color: rgba(var(--yellow-color-rgb), 1) !important;
}

.bg-yellow-light {
  background-color: rgba(var(--yellow-color-rgb), 0.1) !important;
}

.bg-turquoise {
  background-color: rgba(var(--turquoise-color-rgb), 1) !important;
}

.bg-turquoise-light {
  background-color: rgba(var(--turquoise-color-rgb), 0.1) !important;
}

.bg-blue {
  background-color: rgba(var(--blue-color-rgb), 1) !important;
}

.bg-blue-light {
  background-color: rgba(var(--blue-color-rgb), 0.1) !important;
}

.bg-border-gray {
  background-color: rgba(var(--border-gray-color-rgb), 1) !important;
}

.bg-border-gray-light {
  background-color: rgba(var(--border-gray-color-rgb), 0.1) !important;
}

.bg-lighter-gray {
  background-color: rgba(var(--lighter-gray-color-rgb), 1) !important;
}

.bg-lighter-gray-light {
  background-color: rgba(var(--lighter-gray-color-rgb), 0.1) !important;
}

.bg-white {
  background-color: rgba(var(--white-color-rgb), 1) !important;
}

.bg-white-light {
  background-color: rgba(var(--white-color-rgb), 0.1) !important;
}

.bg-topship {
  background-color: rgba(var(--topship-color-rgb), 1) !important;
}

.bg-topship-light {
  background-color: rgba(var(--topship-color-rgb), 0.1) !important;
}

.bg-black {
  background-color: rgba(var(--black-color-rgb), 1) !important;
}

.bg-black-light {
  background-color: rgba(var(--black-color-rgb), 0.1) !important;
}

.bg-neutral {
  background-color: rgba(var(--neutral-color-rgb), 1) !important;
}

.bg-neutral-light {
  background-color: rgba(var(--neutral-color-rgb), 0.1) !important;
}

.background-default {
  background-color: var(--default-bg-color) !important;
}

.background-dark {
  background-color: var(--dark-bg-color) !important;
}

.background-light {
  background-color: var(--light-bg-color) !important;
}

#toast-container {
  position: fixed !important;
  top: unset !important;
  bottom: 15px !important;
  right: 15px !important;
  z-index: 9999;
}

#toast-container > .toast {
  background-image: none !important;
  margin: 0 0 7px !important;
  padding: 15px 20px !important;
  opacity: 1 !important;
  font-size: 14px;
  box-shadow: none !important;
  width: 400px;
}

#toast-container > .toast:before {
  font-family: "FontAwesome";
  float: left;
  font-size: 14px;
  color: #FFF;
  padding-right: 10px;
}

#toast-container > .toast-warning:before {
  content: "\f071";
}

#toast-container > .toast-error:before {
  content: "\f06a";
}

#toast-container > .toast-info:before {
  content: "\f05a";
}

#toast-container > .toast-success:before {
  content: "\f05d";
}

@media (max-width: 767px) {
  #toast-container {
    position: fixed !important;
    top: 0 !important;
    bottom: unset !important;
    right: unset !important;
  }
  #toast-container > div {
    width: 100% !important;
    border-radius: 0;
  }
}
@media print {
  #toast-container {
    display: none !important;
  }
}
.etop-table .etop-table-header th,
etop-table-new .etop-table-header th {
  font-weight: normal;
  border-right: 1px solid #fff;
  border-top: none;
  padding: 0.5rem 0.75rem;
  vertical-align: middle;
  background: var(--primary-color);
  color: white;
}

.table-header-text {
  font-size: 0.8rem;
  height: 25px;
  align-items: center !important;
  display: flex !important;
}

.table-row {
  background-color: #fff;
  margin-bottom: 10px;
  vertical-align: middle;
  border-radius: 5px;
  min-height: 60px;
  cursor: pointer;
}
.table-row.row {
  margin-left: 0;
  margin-right: 0;
}
.table-row .fa-minus {
  color: #ddd;
}

etop-table-new .table-row td {
  position: relative;
}
etop-table-new .table-row.active {
  box-shadow: inset 5px 0 0 var(--primary-color);
  margin-left: 0;
  border-left: none;
}

.transition {
  transition: 0.2s;
}

.anm-slide-right {
  animation: slide-right 0.4s ease;
}

.anm-slide-down {
  animation: slide-down 0.4s ease;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
select {
  background-color: white;
  outline: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -3px;
  appearance: none;
  padding-right: 1.75rem;
  color: #6e6e6e;
}

select.select-align-right {
  text-align-last: right;
}

select.label-select {
  border: none;
  outline: none;
  color: #444;
  cursor: pointer;
}

.order-source {
  font-weight: 700;
}
.order-source.order-default {
  color: var(--default-color) !important;
}

.order-source {
  font-weight: 700;
}
.order-source.order-ts_app {
  color: var(--topship-color) !important;
}

.order-source {
  font-weight: 700;
}
.order-source.order-etop_pos {
  color: var(--secondary-color) !important;
}

.order-source {
  font-weight: 700;
}
.order-source.order-etop_pxs {
  color: var(--yellow-color) !important;
}

.order-source {
  font-weight: 700;
}
.order-source.order-etop_cmx {
  color: var(--turquoise-color) !important;
}

.order-source {
  font-weight: 700;
}
.order-source.order-haranvan {
  color: var(--tertiary-color) !important;
}

.order-source {
  font-weight: 700;
}
.order-source.order-api {
  color: var(--tertiary-color) !important;
}

.order-source {
  font-weight: 700;
}
.order-source.order-self {
  color: var(--blue-color) !important;
}

.order-source {
  font-weight: 700;
}
.order-source.order-import {
  color: var(--blue-color) !important;
}

.order-status {
  color: var(--default-color) !important;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #33b6d4;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e67e22;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #33b6d4;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #e67e22;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: black;
  background: #33b6d4;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #e67e22;
  color: black;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #33b6d4;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #e67e22;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #33b6d4;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #e67e22;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: black;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: black;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #33b6d4;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #e67e22;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #33b6d4;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e67e22;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #33b6d4;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #e67e22;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #33b6d4;
  color: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #e67e22;
  color: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(51, 182, 212, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(51, 182, 212, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(51, 182, 212, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #33b6d4;
  color: black;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(51, 182, 212, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(51, 182, 212, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(51, 182, 212, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(230, 126, 34, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(230, 126, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(230, 126, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e67e22;
  color: black;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(230, 126, 34, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(230, 126, 34, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(230, 126, 34, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #33b6d4;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #e67e22;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #33b6d4;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #e67e22;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #e67e22;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #33b6d4;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #e67e22;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #33b6d4;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #e67e22;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #33b6d4;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #e67e22;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #33b6d4;
}
.mat-icon.mat-accent {
  color: #e67e22;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #33b6d4;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #e67e22;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #c8e9f1;
}

.mat-progress-bar-buffer {
  background-color: #c8e9f1;
}

.mat-progress-bar-fill::after {
  background-color: #33b6d4;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f5dbc4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f5dbc4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #e67e22;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #33b6d4;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #e67e22;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #33b6d4;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #33b6d4;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e67e22;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #e67e22;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #33b6d4;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #e67e22;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #e67e22;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(230, 126, 34, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #e67e22;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #33b6d4;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(51, 182, 212, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #33b6d4;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #33b6d4;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: black;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(51, 182, 212, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #e67e22;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: black;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(230, 126, 34, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: black;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #33b6d4;
  color: black;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: black;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e67e22;
  color: black;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(194, 233, 242, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #33b6d4;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 216, 189, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #e67e22;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(194, 233, 242, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #33b6d4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 216, 189, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #e67e22;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #33b6d4;
  color: black;
}
.mat-toolbar.mat-accent {
  background: #e67e22;
  color: black;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #e67e22;
}

/** TABLE OF CONTENTS
 ----------------------------------------------------------- /
1 - Mat TABS
2 - Mat MENUS
3 - Mat CHIPS
4 - Mat CHECKBOX
5 - Mat RADIO
6 - DANGER Color
7 - TOPSHIP Color
8 - PRIMARY Color
/// END TABLE OF CONTENTS **/
/** 1 - Mat TABS
------------------------------------------------------------*/
.mat-tab-header {
  border-bottom: 1px solid var(--gray-color) !important;
}

.mat-tab-body-wrapper {
  flex: 1;
}

.mat-tab-label {
  height: 3.5rem !important;
  min-width: unset !important;
  padding: 0 5rem 0 0 !important;
  text-align: left !important;
  justify-content: flex-start !important;
}

.centered .mat-tab-label {
  padding: 0 1rem !important;
  text-align: center !important;
  justify-content: center !important;
}

.equaled .mat-tab-label {
  flex: 1;
}

.mat-tab-label-active {
  opacity: 1 !important;
}

.mat-ink-bar {
  height: 3px !important;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary-color);
}

/** 2 - Mat MENUS
------------------------------------------------------------*/
.mat-menu-item {
  font-family: inherit !important;
  font-size: 1rem !important;
  padding: 0.9rem 1.25rem !important;
  line-height: unset !important;
  height: auto !important;
}
.mat-menu-item .mat-icon {
  font-size: 1.5rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-right: 0.5rem !important;
  color: inherit;
}

.mat-menu-content {
  padding: 0 !important;
}

.mat-menu-panel {
  min-height: unset !important;
  max-width: unset !important;
}

/** 3 - Mat CHIPS
------------------------------------------------------------*/
.mat-chip {
  font-size: 1rem !important;
}

.mat-standard-chip {
  min-height: 18px !important;
}

.mat-chip .mat-chip-remove.mat-icon,
.mat-chip .mat-chip-trailing-icon.mat-icon {
  font-size: 10px !important;
}

.mat-standard-chip .mat-chip-remove.mat-icon {
  width: 10px !important;
  height: 10px !important;
}

mat-chip.mat-standard-chip:not(.mat-chip-selected), .mat-chip.mat-standard-chip::after {
  background-color: #eeeeee;
  color: #444;
}

.mat-chip.mat-standard-chip.mat-chip-selected {
  color: #fff !important;
}

.mat-chip-list-wrapper .mat-standard-chip {
  margin: 2px !important;
}

/** 4 - Mat CHECKBOX
------------------------------------------------------------*/
.mat-checkbox-layout {
  white-space: pre-line !important;
}
.mat-checkbox-layout .mat-checkbox-label {
  line-height: normal;
}

.mat-checkbox-inner-container {
  margin-right: 0.5rem;
}

.mat-checkbox-frame {
  border-width: 1px !important;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--primary-color) !important;
}

/** 5 - Mat RADIO
------------------------------------------------------------*/
.mat-radio-label-content {
  line-height: 15px !important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-color) !important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-inner-circle {
  background-color: var(--primary-color) !important;
}

.mat-radio-container, .mat-radio-outer-circle, .mat-radio-inner-circle {
  width: 17px !important;
  height: 17px !important;
}

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.6) !important;
}

.mat-radio-outer-circle {
  border-width: 1px !important;
}

/** 6 - DANGER-COLOR
------------------------------------------------------------*/
.error .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-start,
.error .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-end {
  border-color: var(--danger-color) !important;
}
.error .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-bottom-color: var(--danger-color) !important;
}
.error .mat-form-field-appearance-standard .mat-form-field-ripple {
  background-color: var(--danger-color);
}
.error .mat-input-element {
  caret-color: var(--danger-color) !important;
}
.error .mat-form-field.mat-focused .mat-form-field-label {
  color: var(--danger-color) !important;
}
.error .mat-tab-label-active .mat-tab-label-content {
  color: var(--danger-color) !important;
}
.error .mat-form-field.mat-focused.mat-primary .mat-select-arrow, .error.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--danger-color) !important;
}
.error.mat-primary .mat-pseudo-checkbox-checked, .error.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--danger-color) !important;
}

/** 7 - TOPSHIP-COLOR
------------------------------------------------------------*/
.topship .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-start,
.topship .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-gap,
.topship .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-end {
  border-color: var(--topship-color) !important;
}
.topship .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-top-color: transparent !important;
}
.topship .mat-form-field-appearance-standard .mat-form-field-ripple {
  background-color: var(--topship-color);
}
.topship .mat-input-element {
  caret-color: var(--topship-color) !important;
}
.topship .mat-form-field.mat-focused .mat-form-field-label {
  color: var(--topship-color) !important;
}
.topship .mat-tab-label-active .mat-tab-label-content {
  color: var(--topship-color) !important;
}
.topship .mat-form-field.mat-focused.mat-primary .mat-select-arrow, .topship.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--topship-color) !important;
}
.topship.mat-primary .mat-pseudo-checkbox-checked, .topship.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--topship-color) !important;
}

/** 8 - PRIMARY-COLOR
------------------------------------------------------------*/
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-end {
  border-color: var(--primary-color) !important;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-bottom-color: var(--primary-color) !important;
}

.mat-form-field-appearance-standard .mat-form-field-ripple {
  background-color: var(--primary-color) !important;
}

.mat-input-element {
  caret-color: var(--primary-color) !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--primary-color) !important;
}

.mat-tab-label-active .mat-tab-label-content {
  color: var(--primary-color) !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--primary-color) !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--primary-color) !important;
}

.mat-form-field {
  width: 100% !important;
}

.mat-option-text {
  overflow: visible !important;
}

.no-padding .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 !important;
}

.mat-form-field-infix {
  display: flex !important;
  align-items: center;
  padding: 0.5rem 0;
}
.mat-form-field-infix mat-icon {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

.mat-form-field-invalid .mat-input-element,
.mat-warn .mat-input-element {
  caret-color: var(--danger-color) !important;
}

.mat-select-disabled .mat-select-value {
  cursor: not-allowed;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: red !important;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 0 5px !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: var(--danger-color) !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

mat-hint {
  padding-top: 12px;
}

.mat-slide-toggle-content {
  font-family: inherit;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary-color) !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--primary-color-rgb), 0.54) !important;
}

.mat-select-trigger {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.mat-select-value {
  display: block !important;
  max-width: unset !important;
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled),
.mat-option.mat-active,
.mat-select-panel .mat-option.mat-selected {
  background: #f5f5f5 !important;
}

.mat-option.mat-option-disabled {
  color: initial !important;
  opacity: 0.5;
}

.mat-calendar-body-selected {
  color: #fff;
}

.cdk-virtual-scroll-content-wrapper {
  min-width: unset !important;
  width: 100% !important;
}

.mat-icon {
  font-size: 1rem !important;
  width: 1rem !important;
  height: 1rem !important;
}
.mat-icon.mat-small-icon {
  font-size: 0.5rem !important;
  width: 0.5rem !important;
  height: 0.5rem !important;
}
.mat-icon.mat-big-icon {
  font-size: 1.5rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.number-formatted {
  overflow-x: auto;
}

.mat-form-field-appearance-outline {
  background-color: white !important;
  border-radius: 8px;
}
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-label-wrapper {
  top: -0.64375em;
  padding-top: 0.74375em;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  line-height: 14px !important;
}
.mat-form-field-appearance-outline .mat-form-field-infix input {
  padding: 0 5px !important;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-color: transparent !important;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: var(--danger-color) !important;
  opacity: 0.8 !important;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  cursor: not-allowed;
  background: #fafafa;
  color: #888 !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end {
  border-width: 2px !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-color: var(--border-gray-color) !important;
}
.mat-form-field-appearance-outline .number-formatted {
  padding: 9px 0;
  flex: 1;
}

.mat-form-field-appearance-standard .mat-form-field-outline {
  background: #f5f5f5 !important;
  border-radius: 5px !important;
}
.mat-form-field-appearance-standard .mat-form-field-underline {
  bottom: -3px !important;
  background-color: #e5e5e5 !important;
}
.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}
.mat-form-field-appearance-standard .mat-form-field-label {
  padding-left: 0 !important;
}
.mat-form-field-appearance-standard .number-formatted {
  height: 15px;
  line-height: 15px;
  width: 100%;
}
.mat-form-field-appearance-standard .mat-form-field-empty.mat-form-field-label,
.mat-form-field-appearance-standard input.mat-input-element,
.mat-form-field-appearance-standard mat-select {
  height: 1.25rem !important;
  line-height: 1.25rem !important;
  margin-top: 0 !important;
}
.mat-form-field-appearance-standard .mat-select-arrow-wrapper {
  transform: none !important;
}

.disabled {
  cursor: not-allowed !important;
}
.disabled input, .disabled textarea {
  color: #888 !important;
}
.disabled .mat-form-field-appearance-outline .mat-form-field-outline {
  background: #f5f5f5 !important;
  border-radius: 5px !important;
}

.tag-default .mat-checkbox-background {
  background-color: var(--default-color) !important;
}

.tag-red .mat-checkbox-background {
  background-color: var(--red-color) !important;
}

.tag-green .mat-checkbox-background {
  background-color: var(--green-color) !important;
}

.tag-blue .mat-checkbox-background {
  background-color: var(--blue-color) !important;
}

.tag-purple .mat-checkbox-background {
  background-color: var(--purple-color) !important;
}

.tag-orange .mat-checkbox-background {
  background-color: var(--orange-color) !important;
}

.tag-grey .mat-checkbox-background {
  background-color: var(--grey-color) !important;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white !important;
  border-radius: 5px;
}

.border-default {
  border: 1px solid var(--default-border-color) !important;
}

.border-dark {
  border: 1px solid var(--dark-border-color) !important;
}

.border-light {
  border: 1px solid var(--light-border-color) !important;
}

.border-none {
  border: none !important;
}

.e-border {
  border: 1px solid var(--gray-color);
}

.e-border-top {
  border-top: 1px solid var(--gray-color);
}

.e-border-bottom {
  border-bottom: 1px solid var(--gray-color);
}

.e-border-right {
  border-right: 1px solid var(--gray-color);
}

.e-border-left {
  border-left: 1px solid var(--gray-color);
}

.large-tooltips .tooltip-inner {
  width: 25rem;
  max-width: unset;
}

.text-left .tooltip-inner {
  text-align: left;
  padding: 0.5rem 1rem;
  margin: 0;
  font-size: 0.9rem;
}

.modal-content {
  border-radius: 10px;
}

.modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-header, .modal-footer {
  border-color: var(--border-gray-color);
}

.modal-xxl {
  max-width: 95% !important;
}

.modal-title {
  font-weight: 700;
  font-size: initial;
  flex: 1;
  text-align: center;
}

.modal-radius .modal-content {
  border-radius: 8px;
  border-color: white;
}

.status {
  color: var(--default-color) !important;
}
.status::before {
  content: "";
  display: inline-block;
  margin-right: 4px;
  background-color: var(--default-color);
  padding: 3px;
  position: relative;
  top: -1px;
  border-radius: 100%;
}

.status.status-default {
  color: var(--default-color) !important;
}
.status.status-default::before {
  background-color: var(--default-color);
}

.status.status-new {
  color: var(--default-color) !important;
}
.status.status-new::before {
  background-color: var(--default-color);
}

.status.status-created {
  color: var(--default-color) !important;
}
.status.status-created::before {
  background-color: var(--default-color);
}

.status.status-unknown {
  color: var(--default-color) !important;
}
.status.status-unknown::before {
  background-color: var(--default-color);
}

.status.status-processing {
  color: var(--topship-color) !important;
}
.status.status-processing::before {
  background-color: var(--topship-color);
}

.status.status-picking {
  color: var(--topship-color) !important;
}
.status.status-picking::before {
  background-color: var(--topship-color);
}

.status.status-holding {
  color: var(--topship-color) !important;
}
.status.status-holding::before {
  background-color: var(--topship-color);
}

.status.status-delivering {
  color: var(--topship-color) !important;
}
.status.status-delivering::before {
  background-color: var(--topship-color);
}

.status.status-returning {
  color: var(--topship-color) !important;
}
.status.status-returning::before {
  background-color: var(--topship-color);
}

.status.status-undeliverable {
  color: var(--danger-color) !important;
}
.status.status-undeliverable::before {
  background-color: var(--danger-color);
}

.status.status-cancelled {
  color: var(--danger-color) !important;
}
.status.status-cancelled::before {
  background-color: var(--danger-color);
}

.status.status-error {
  color: var(--danger-color) !important;
}
.status.status-error::before {
  background-color: var(--danger-color);
}

.status.status-fail {
  color: var(--danger-color) !important;
}
.status.status-fail::before {
  background-color: var(--danger-color);
}

.status.status-delivered {
  color: var(--success-color) !important;
}
.status.status-delivered::before {
  background-color: var(--success-color);
}

.status.status-success {
  color: var(--success-color) !important;
}
.status.status-success::before {
  background-color: var(--success-color);
}

.status.status-received {
  color: var(--tertiary-color) !important;
}
.status.status-received::before {
  background-color: var(--tertiary-color);
}

.status.status-returned {
  color: var(--tertiary-color) !important;
}
.status.status-returned::before {
  background-color: var(--tertiary-color);
}

.status.status-ignore {
  color: var(--neutral-color) !important;
}
.status.status-ignore::before {
  background-color: var(--neutral-color);
}

.status.status-Z {
  color: var(--default-color) !important;
}
.status.status-Z::before {
  background-color: var(--default-color);
}

.status.status-N {
  color: var(--danger-color) !important;
}
.status.status-N::before {
  background-color: var(--danger-color);
}

.status.status-S {
  color: var(--topship-color) !important;
}
.status.status-S::before {
  background-color: var(--topship-color);
}

.status.status-NS {
  color: var(--tertiary-color) !important;
}
.status.status-NS::before {
  background-color: var(--tertiary-color);
}

.status.status-P {
  color: var(--success-color) !important;
}
.status.status-P::before {
  background-color: var(--success-color);
}

/* deprecated */
.order-status {
  color: var(--default-color) !important;
}
.order-status::before {
  content: "";
  display: inline-block;
  margin-right: 4px;
  background-color: var(--default-color);
  padding: 3px;
  border-radius: 100%;
}

.order-status.order-default {
  color: var(--default-color) !important;
}
.order-status.order-default::before {
  background-color: var(--default-color);
}

.order-status.order-new {
  color: var(--default-color) !important;
}
.order-status.order-new::before {
  background-color: var(--default-color);
}

.order-status.order-created {
  color: var(--default-color) !important;
}
.order-status.order-created::before {
  background-color: var(--default-color);
}

.order-status.order-unknown {
  color: var(--default-color) !important;
}
.order-status.order-unknown::before {
  background-color: var(--default-color);
}

.order-status.order-processing {
  color: var(--topship-color) !important;
}
.order-status.order-processing::before {
  background-color: var(--topship-color);
}

.order-status.order-picking {
  color: var(--topship-color) !important;
}
.order-status.order-picking::before {
  background-color: var(--topship-color);
}

.order-status.order-holding {
  color: var(--topship-color) !important;
}
.order-status.order-holding::before {
  background-color: var(--topship-color);
}

.order-status.order-delivering {
  color: var(--topship-color) !important;
}
.order-status.order-delivering::before {
  background-color: var(--topship-color);
}

.order-status.order-returning {
  color: var(--topship-color) !important;
}
.order-status.order-returning::before {
  background-color: var(--topship-color);
}

.order-status.order-undeliverable {
  color: var(--danger-color) !important;
}
.order-status.order-undeliverable::before {
  background-color: var(--danger-color);
}

.order-status.order-cancelled {
  color: var(--danger-color) !important;
}
.order-status.order-cancelled::before {
  background-color: var(--danger-color);
}

.order-status.order-error {
  color: var(--danger-color) !important;
}
.order-status.order-error::before {
  background-color: var(--danger-color);
}

.order-status.order-fail {
  color: var(--danger-color) !important;
}
.order-status.order-fail::before {
  background-color: var(--danger-color);
}

.order-status.order-delivered {
  color: var(--success-color) !important;
}
.order-status.order-delivered::before {
  background-color: var(--success-color);
}

.order-status.order-success {
  color: var(--success-color) !important;
}
.order-status.order-success::before {
  background-color: var(--success-color);
}

.order-status.order-received {
  color: var(--tertiary-color) !important;
}
.order-status.order-received::before {
  background-color: var(--tertiary-color);
}

.order-status.order-returned {
  color: var(--tertiary-color) !important;
}
.order-status.order-returned::before {
  background-color: var(--tertiary-color);
}

.order-status.order-ignore {
  color: var(--neutral-color) !important;
}
.order-status.order-ignore::before {
  background-color: var(--neutral-color);
}

.order-status.order-Z {
  color: var(--default-color) !important;
}
.order-status.order-Z::before {
  background-color: var(--default-color);
}

.order-status.order-N {
  color: var(--danger-color) !important;
}
.order-status.order-N::before {
  background-color: var(--danger-color);
}

.order-status.order-S {
  color: var(--topship-color) !important;
}
.order-status.order-S::before {
  background-color: var(--topship-color);
}

.order-status.order-NS {
  color: var(--tertiary-color) !important;
}
.order-status.order-NS::before {
  background-color: var(--tertiary-color);
}

.order-status.order-P {
  color: var(--success-color) !important;
}
.order-status.order-P::before {
  background-color: var(--success-color);
}

.mat-dialog-container {
  border-radius: 10px !important;
  min-width: 400px;
}

.matdialog-container {
  display: block;
  margin: -24px;
  color: #444;
}

.dialog-header, .dialog-footer {
  padding: 1rem;
}

.dialog-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--gray-color);
  position: relative;
}
.dialog-header .close-button {
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
}
.dialog-header .close-button .mat-icon {
  font-size: 1.5rem !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.dialog-title {
  font-weight: 700;
  font-size: 1.5rem;
  flex: 1;
  text-align: center;
}

.dialog-body {
  padding: 1rem 2rem;
}

.dialog-footer {
  border-top: 1px solid var(--gray-color);
}