.layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  animation: pulse 2s;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #444444;
  width: calc(100% - var(--sidebar-width));
}

.show-768 {
  @media only screen and (min-width: 768px) {
    display: none !important;
  }
}

.hide-768 {
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  #toast-container {
    padding: 0 12px;
    width: 100%;
    position: fixed !important;
    top: 12px !important;
    bottom: unset !important;
    //right: unset !important;
    left: 0px !important;
  }

  #toast-container > .toast {
    max-width: 100%;
  }

  .etelecom-ticket {
    .table-header {
      display: none;
    }
  }
}

.sidebar {
  position: relative;
  background: #fff;
  z-index: 1001;
  height: 100%;
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.05);
  width: var(--sidebar-width);

  &:hover {
    .slider-content {
      left: var(--sidebar-width);
    }
  }
}

.header {
  position: relative;
  height: var(--header-height);
}

.content {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  &:has(etop-table-new) {
    padding: 1.5rem 1.5rem 0;
  }
}

.unchangeable {
  cursor: not-allowed;

  * {
    background: #ddd;
    word-break: keep-all;
    overflow-x: auto;
  }
}

.unclickable {
  cursor: not-allowed !important;
  color: #888 !important;
  opacity: .8;
}

.code {
  background: #f7f7f7;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;
  font-weight: 700;
}

.badge {
  border-radius: 5px;
  background: #f8f8f8;
  border: 1px solid #f0f0f0;
}

.flex-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .total {
    border-radius: 12px;
    background: transparent !important;
  }
}

.page-content {
  flex: 1;
  position: relative;
  padding-top: 1rem;
  overflow: hidden;

  &:has(etop-filter) {
  }
}

.page-wrapper {
  display: flex;
  position: relative;
  height: 100%;
}

.content-box td {
  padding: 6px 15px;
  border-top: none;
}

.content-box {
  background: white;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.body-transaction-detail {
  padding: 15px 15px 0;
}

.body-transaction-detail .container {
  max-width: 100% !important;
}

.alert-warning {
  height: 100%;
}

.h100 {
  height: 100%;
}

.bmt-0 {
  width: 30%;
}

.card-body {
  padding: 0;
}

.card-header {
  padding: 0;
  border: none;
}

.card-header-item {
  padding: 10px;

  .header-title {
    font-size: 11px;
  }
}

.slider-detail-info-title {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: .9rem;
  margin-bottom: 1.25rem;
}

.slider-detail-input-item {
  margin-bottom: 0.5rem;
}

.slider-detail-section {
  padding: 1.25rem;
  border-bottom: 1px solid var(--border-gray-color);

  &:last-child {
    border-bottom: none;
  }
}

.slider-detail-section-table {
  border-top: 1rem solid rgba(0,0,0,.04);
  thead {
    background: rgba(0, 0, 0, 0.01);
  }
  th {
    color: var(--default-color);
    font-weight: 400;
    padding: 7px 15px;
    border-bottom: 1px solid var(--border-gray-color);
    border-top: 1px solid var(--border-gray-color);
  }
  td {
    padding: 10px 15px;
    font-weight: 700;
  }
  tr {
    border-bottom: 1px solid var(--border-gray-color);
  }
}

.slider-loading, .slider-empty {
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.slider-empty {
  border-bottom: 1px solid var(--border-gray-color);
}

.slider-loading {
  .fa {
    font-size: 1.75rem;
  }
}

.slider-detail-full-img {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    object-position: center;
    width: 70%;
  }
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs li {
  a {
    padding: 1rem 1.5rem;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    background: #f3f3f3;
    border-bottom: 2px solid transparent;
    min-height: 48px;

    &.active {
      background: #fff;
      border-bottom: 2px solid var(--warning-color);
    }

    &:hover {
      color: var(--warning-color);
    }
  }
}

.fa,
.fas {
  color: #6e6e6e;
}

.dropdown-item:active {
  color: initial;
}

.dropdown-item:focus {
  background-color: initial;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.font-weight-bolder {
  font-weight: 500 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.no-permission {
  cursor: not-allowed;

  * {
    pointer-events: none;
  }
}

.bigger-font {
  font-size: 1.5rem !important;
}

.big-font {
  font-size: 1.3rem !important;
}

.upper-medium-font {
  font-size: 1.1rem !important;
}

.lower-medium-font {
  font-size: .9rem !important;
}

.small-font {
  font-size: .8rem !important;
}

.smaller-font {
  font-size: .6rem !important;
}

@page {
  size: auto;
}

@media screen {
  .print-me,
  .print-me * {
    display: none !important;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .content {
    overflow: visible !important;
  }
  body, .layout {
    min-width: unset !important;
  }
  .print-page {
    page-break-after: always;
  }
  .print-me,
  .print-me * {
    visibility: visible;
  }
}

.trading-box {
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.mat-input {
  border: 1px solid #dee2e6 !important;
  border-radius: 5px;
  padding: 0.75rem;
}

.textlable {
  position: absolute;
  top: -0.8rem;
  left: 0.6rem;
  background: white;
  font-size: 9px;
  padding: 0 4px;
  opacity: 0.8;
}

.aff-box {
  background-color: rgb(250, 251, 251);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: rgba(90, 114, 123, 0.11) 0px 7px 30px 0px;
  border: none;
}

.not-allowed {
  cursor: not-allowed;
}

.cdk-overlay-container {
  z-index: 2000 !important;
}

.read-only {
  cursor: not-allowed;
  display: inline-flex;
  align-items: center;
  padding: 0 .4rem;
}

.external-link-icon {
  font-size: .8rem;
  position: relative;
  top: -1px;
  left: 3px;
}

.wd-100 {
  min-width: 100px !important;
  max-width: 100px !important;
}

.wd-120 {
  min-width: 120px !important;
  max-width: 120px !important;
}

.wd-130 {
  min-width: 130px !important;
  max-width: 130px !important;
}

.wd-150 {
  min-width: 150px !important;
  max-width: 150px !important;
}

.md-drppicker {
  margin-top: 10px !important;

  .btn {
    background-color: var(--primary-color) !important;
    line-height: 1px !important;
    height: 30px !important;
    padding: 0 20px !important;
  }

  .ranges {
    button.active {
      background-color: var(--primary-color) !important;
    }
  }

  td.active, td.active:hover {
    background-color: var(--primary-color) !important;
    border-color: transparent;
    color: #fff;
  }
}

.position-right {
  .md-drppicker {
    left: auto !important;
    right: 0 !important;
  }
}

.grecaptcha-badge {
  display: none !important;
}

.show-table-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .6rem 1rem;
  background: #fafafa;
  border-radius: 5px;
  border: 1px solid #eee;
  cursor: pointer;

  .fa {
    font-size: 1.5rem;
  }
}

.carrier-info {
  padding-left: .7rem;
  flex: 1;

  .shipping-code {
    font-weight: 700;
    word-break: break-all;
  }

  .carrier-name {
    font-size: 0.7rem;

    * {
      font-size: 0.7rem;
    }
  }
}

.carrier-logo {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 100%;
  object-fit: contain;
  object-position: center;
}

.carrier-image {
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  border: 1px solid var(--border-gray-color);

  img:not(.topship-logo) {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &.small {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .topship-logo {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    bottom: -3px;
    right: 0;
  }
}

.box {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  border: 1px solid #eee;
  background-color: white;
}

.row {
  margin-left: -1.25rem;
  margin-right: -1.25rem;

  [class*='col-'] {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}


.total {
  width: 100%;
  padding: 0 2rem;

  .total-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: -2rem;
    margin-right: -2rem;
    border-bottom: 1px solid var(--gray-color);

    &.left-aligned {
      justify-content: flex-start;

      .total-item {
        text-align: left;
      }
    }
  }

  .total-item {
    text-align: right;
    padding: 0 !important;
  }

  .item-value {
    font-size: 1.15rem;

    * {
      font-size: 1.15rem;
    }
  }
}

.blank-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .blank-img {
    width: 240px;
    height: auto;
  }
}

.fake-material-input-outline {
  height: 40px;
  border: 1px solid var(--border-gray-color);
  border-radius: 5px;
  padding: 10px;

  .mat-icon {
    font-size: 1.25rem !important;
    width: 1.25rem !important;
    height: 1.25rem !important;
    cursor: pointer;
  }
}

.fake-material-input-standard {
  border-bottom: 1px solid var(--border-gray-color);
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3px;

  .mat-icon {
    font-size: 1.25rem !important;
    width: 1.25rem !important;
    height: 1.25rem !important;
    cursor: pointer;
  }

  .fake-material-input-standard-content {
    flex: 1;
    overflow: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      height: 0;
      background: transparent; /* Optional: just make scrollbar invisible */
    }
  }

  &:hover {
    border-bottom: 2px solid var(--primary-color);

    &.topship {
      border-bottom: 2px solid var(--topship-color);
    }
  }

  &.error {
    border-bottom: 2px solid var(--danger-color);
    color: var(--danger-color);
  }

  &.placeholder {
    font-weight: 400;
    color: #999 !important;
  }

  &.disabled {
    border-bottom: 1px dashed #ccc !important;
    color: #999 !important;

    &:hover {
      border-bottom: 1px dashed #ccc !important;
      color: #999 !important;
    }

    .fake-material-input-standard-content {
      pointer-events: none;
    }
  }
}

.empty {
  padding: 1rem 0;
  text-align: center;
  background: var(--gray-color);
  border: 1px solid var(--border-gray-color);
  border-radius: 5px;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}

.upload-logo {
  position: relative;
  margin: 0 !important;

  img {
    cursor: pointer;
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    border: 1px solid #eee;
    border-radius: 50%;
  }

  .over-logo,
  .upload-loading {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 100%;
    color: #fff;
    font-size: 1.15rem;

    i {
      margin: auto;
    }
  }

  .over-logo {
    display: none;
  }

  .upload-loading {
    display: flex;
    align-items: center;
    justify-content: center;

    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: #fff !important;
    }

    i {
      margin: auto;
    }
  }

  img:hover + .over-logo {
    display: flex;
  }

  .over-logo:hover {
    display: flex;
  }
}

.min-width-12rem {
  min-width: 12rem;
}

.min-width-15rem {
  min-width: 15rem;
}

.min-width-20rem {
  min-width: 20rem;
}

.min-width-25rem {
  min-width: 25rem;
}

.max-width-20rem {
  max-width: 20rem;
}

.max-width-7rem {
  max-width: 7rem;
}

.show-ranges {
  width: max-content !important;
}

.expire-time-soon{
  color: rgb(231,76,60);
  background-color: rgb(231,76,60,0.1);
  padding: 5px 15px;
  border-radius: 20px;
}

.expire-time{
  color: var(--white-color);
  background-color: var(--danger-color);
  padding: 5px 15px;
  border-radius: 20px;
}

.dialog-responsive {
  @media screen and (max-width: 767px){
    min-width: 50vw !important;
  }
}

.fix-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fix-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fix-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.close-item {
  background: var(--default-color);
  border-radius: 50%;
  margin: auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-content: center;
  flex-flow: row wrap;
  justify-content: center;
  color: white;
}

::ng-deep .mat-checkbox-layout {
  align-items: center !important;
}